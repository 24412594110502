$transition-time-base: .3s;

$main-clr: #FFD249;
$main-clr-hover: #E5A701;
$alt-clr: #282828;
$text-clr: #282828;

$black: #1F2026;
$black1: #2C2D34;
$black3: #393D39;

$gray: #383A44;
$gray1: #ECECEC;
$gray2: #A4A4A4;
$gray3: #b5b7c5;
$gray4: #34363F;
$gray5: #8C8E98;
$gray6: #404040;

$gray1-text: #707070;

$white1: #f5f5f5;
$white1-text: #8D8D8D;
$white-text: #8C939F;

$cart-count: #4066EB;

$border1: #40BAEB;



$main-gradient: linear-gradient(90deg, #40BAEB 0%, #4066EB 100%);
$main-gradient-hover: linear-gradient(90deg, #7ED3F5 0%, #8FA5F2 100%);
$black-gradient: linear-gradient(91.17deg, #2B343B 0%, #0B1516 44.47%, #1B2023 74.71%, #070C12 100%);

$black1-text: #B5B7C5;
$black1-text1: #fff;