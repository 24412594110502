@mixin font-h1{
	font-weight: 700;
	font-size: calcFluid(28,45);
	line-height: calcFluid(32,52);
}
@mixin font-h2{
	font-weight: 700;
	font-size: calcFluid(24,36);
	line-height: calcFluid(28,42);
}
@mixin font-h3{
	font-weight: 700;
	font-size: calcFluid(18,24);
	line-height: calcFluid(22,30);
}
@mixin font-h4 {
	font-weight: 700;
	font-size: calcFluid(18,20);
	line-height: calcFluid(24,26);
}
@mixin font-p{
	font-size: calcFluid(14,16);
	line-height: calcFluid(18,24);
}


@mixin gradient-text($bg) {
	background: $bg;
  	-webkit-background-clip: text;
  	-webkit-text-fill-color: transparent;
}
@mixin gradient-text-important($bg) {
	background: $bg !important;
  	-webkit-background-clip: text;
  	-webkit-text-fill-color: transparent;
}


@mixin clear-gradient-text($color) {
  	-webkit-background-clip: unset;
  	-webkit-text-fill-color: $color;
}

@mixin font($size, $height:null, $weight:null, $color:null) {
  font-size: #{$size}px;
  @if($height) {
    line-height: #{$height}px;
  }
  @if($weight) {
    font-weight: #{$weight};
  }
  @if($color) {
    color: #{$color};
  }

}

@mixin color-transition($t) {
	transition: color $t, background-color $t;
}


@mixin _1920 {
	@media (max-width: 1919px) {
		@content;
	}
}
@mixin _1650 {
	@media (max-width: 1650px) {
		@content
	}
}
@mixin _1440 {
	@media (max-width: 1440px) {
		@content
	}
}
@mixin _1200 {
	@media (max-width: 1199px) {
		@content;
	}
}
@mixin _992 {
	@media (max-width: 991px) {
		@content;
	}
}

@mixin _768 {
	@media (max-width: 767px) {
		@content;
	}
}

@mixin _576 {
	@media (max-width: 575px) {
		@content;
	}
}

@mixin _420 {
	@media (max-width: 419px) {
		@content;
	}
}