@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
.swal2-popup.swal2-toast {
  box-sizing: border-box;
  grid-column: 1/4 !important;
  grid-row: 1/4 !important;
  grid-template-columns: 1fr 99fr 1fr;
  padding: 1em;
  overflow-y: hidden;
  background: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.075), 0 1px 2px rgba(0, 0, 0, 0.075), 1px 2px 4px rgba(0, 0, 0, 0.075), 1px 3px 8px rgba(0, 0, 0, 0.075), 2px 4px 16px rgba(0, 0, 0, 0.075);
  pointer-events: all; }

.swal2-popup.swal2-toast > * {
  grid-column: 2; }

.swal2-popup.swal2-toast .swal2-title {
  margin: .5em 1em;
  padding: 0;
  font-size: 1em;
  text-align: initial; }

.swal2-popup.swal2-toast .swal2-loading {
  justify-content: center; }

.swal2-popup.swal2-toast .swal2-input {
  height: 2em;
  margin: .5em;
  font-size: 1em; }

.swal2-popup.swal2-toast .swal2-validation-message {
  font-size: 1em; }

.swal2-popup.swal2-toast .swal2-footer {
  margin: .5em 0 0;
  padding: .5em 0 0;
  font-size: .8em; }

.swal2-popup.swal2-toast .swal2-close {
  grid-column: 3/3;
  grid-row: 1/99;
  align-self: center;
  width: .8em;
  height: .8em;
  margin: 0;
  font-size: 2em; }

.swal2-popup.swal2-toast .swal2-html-container {
  margin: .5em 1em;
  padding: 0;
  font-size: 1em;
  text-align: initial; }

.swal2-popup.swal2-toast .swal2-html-container:empty {
  padding: 0; }

.swal2-popup.swal2-toast .swal2-loader {
  grid-column: 1;
  grid-row: 1/99;
  align-self: center;
  width: 2em;
  height: 2em;
  margin: .25em; }

.swal2-popup.swal2-toast .swal2-icon {
  grid-column: 1;
  grid-row: 1/99;
  align-self: center;
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0 .5em 0 0; }

.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 1.8em;
  font-weight: 700; }

.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  top: .875em;
  width: 1.375em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: .3125em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: .3125em; }

.swal2-popup.swal2-toast .swal2-actions {
  justify-content: flex-start;
  height: auto;
  margin: 0;
  margin-top: .5em;
  padding: 0 .5em; }

.swal2-popup.swal2-toast .swal2-styled {
  margin: .25em .5em;
  padding: .4em .6em;
  font-size: 1em; }

.swal2-popup.swal2-toast .swal2-success {
  border-color: #a5dc86; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 1.6em;
  height: 3em;
  transform: rotate(45deg);
  border-radius: 50%; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -.8em;
  left: -.5em;
  transform: rotate(-45deg);
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -.25em;
  left: .9375em;
  transform-origin: 0 1.5em;
  border-radius: 0 4em 4em 0; }

.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em; }

.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  top: 0;
  left: .4375em;
  width: .4375em;
  height: 2.6875em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line] {
  height: .3125em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=tip] {
  top: 1.125em;
  left: .1875em;
  width: .75em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=long] {
  top: .9375em;
  right: .1875em;
  width: 1.375em; }

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-tip {
  -webkit-animation: swal2-toast-animate-success-line-tip .75s;
  animation: swal2-toast-animate-success-line-tip .75s; }

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-long {
  -webkit-animation: swal2-toast-animate-success-line-long .75s;
  animation: swal2-toast-animate-success-line-long .75s; }

.swal2-popup.swal2-toast.swal2-show {
  -webkit-animation: swal2-toast-show .5s;
  animation: swal2-toast-show .5s; }

.swal2-popup.swal2-toast.swal2-hide {
  -webkit-animation: swal2-toast-hide .1s forwards;
  animation: swal2-toast-hide .1s forwards; }

.swal2-container {
  display: grid;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  grid-template-areas: "top-start     top            top-end" "center-start  center         center-end" "bottom-start  bottom-center  bottom-end";
  grid-template-rows: minmax(-webkit-min-content, auto) minmax(-webkit-min-content, auto) minmax(-webkit-min-content, auto);
  grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto);
  height: 100%;
  padding: .625em;
  overflow-x: hidden;
  transition: background-color .1s;
  -webkit-overflow-scrolling: touch; }

.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4); }

.swal2-container.swal2-backdrop-hide {
  background: 0 0 !important; }

.swal2-container.swal2-bottom-start, .swal2-container.swal2-center-start, .swal2-container.swal2-top-start {
  grid-template-columns: minmax(0, 1fr) auto auto; }

.swal2-container.swal2-bottom, .swal2-container.swal2-center, .swal2-container.swal2-top {
  grid-template-columns: auto minmax(0, 1fr) auto; }

.swal2-container.swal2-bottom-end, .swal2-container.swal2-center-end, .swal2-container.swal2-top-end {
  grid-template-columns: auto auto minmax(0, 1fr); }

.swal2-container.swal2-top-start > .swal2-popup {
  align-self: start; }

.swal2-container.swal2-top > .swal2-popup {
  grid-column: 2;
  align-self: start;
  justify-self: center; }

.swal2-container.swal2-top-end > .swal2-popup, .swal2-container.swal2-top-right > .swal2-popup {
  grid-column: 3;
  align-self: start;
  justify-self: end; }

.swal2-container.swal2-center-left > .swal2-popup, .swal2-container.swal2-center-start > .swal2-popup {
  grid-row: 2;
  align-self: center; }

.swal2-container.swal2-center > .swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  justify-self: center; }

.swal2-container.swal2-center-end > .swal2-popup, .swal2-container.swal2-center-right > .swal2-popup {
  grid-column: 3;
  grid-row: 2;
  align-self: center;
  justify-self: end; }

.swal2-container.swal2-bottom-left > .swal2-popup, .swal2-container.swal2-bottom-start > .swal2-popup {
  grid-column: 1;
  grid-row: 3;
  align-self: end; }

.swal2-container.swal2-bottom > .swal2-popup {
  grid-column: 2;
  grid-row: 3;
  justify-self: center;
  align-self: end; }

.swal2-container.swal2-bottom-end > .swal2-popup, .swal2-container.swal2-bottom-right > .swal2-popup {
  grid-column: 3;
  grid-row: 3;
  align-self: end;
  justify-self: end; }

.swal2-container.swal2-grow-fullscreen > .swal2-popup, .swal2-container.swal2-grow-row > .swal2-popup {
  grid-column: 1/4;
  width: 100%; }

.swal2-container.swal2-grow-column > .swal2-popup, .swal2-container.swal2-grow-fullscreen > .swal2-popup {
  grid-row: 1/4;
  align-self: stretch; }

.swal2-container.swal2-no-transition {
  transition: none !important; }

.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1rem; }

.swal2-popup:focus {
  outline: 0; }

.swal2-popup.swal2-loading {
  overflow-y: hidden; }

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: .8em 1em 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word; }

.swal2-actions {
  display: flex;
  z-index: 1;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 1.25em auto 0;
  padding: 0; }

.swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: .4; }

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)); }

.swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)); }

.swal2-loader {
  display: none;
  align-items: center;
  justify-content: center;
  width: 2.2em;
  height: 2.2em;
  margin: 0 1.875em;
  -webkit-animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  border-width: .25em;
  border-style: solid;
  border-radius: 100%;
  border-color: #2778c4 transparent #2778c4 transparent; }

.swal2-styled {
  margin: .3125em;
  padding: .625em 1.1em;
  transition: box-shadow .1s;
  box-shadow: 0 0 0 3px transparent;
  font-weight: 500; }

.swal2-styled:not([disabled]) {
  cursor: pointer; }

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #7066e0;
  color: #fff;
  font-size: 1em; }

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgba(112, 102, 224, 0.5); }

.swal2-styled.swal2-deny {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #dc3741;
  color: #fff;
  font-size: 1em; }

.swal2-styled.swal2-deny:focus {
  box-shadow: 0 0 0 3px rgba(220, 55, 65, 0.5); }

.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #6e7881;
  color: #fff;
  font-size: 1em; }

.swal2-styled.swal2-cancel:focus {
  box-shadow: 0 0 0 3px rgba(110, 120, 129, 0.5); }

.swal2-styled.swal2-default-outline:focus {
  box-shadow: 0 0 0 3px rgba(100, 150, 200, 0.5); }

.swal2-styled:focus {
  outline: 0; }

.swal2-styled::-moz-focus-inner {
  border: 0; }

.swal2-footer {
  justify-content: center;
  margin: 1em 0 0;
  padding: 1em 1em 0;
  border-top: 1px solid #eee;
  color: #545454;
  font-size: 1em; }

.swal2-timer-progress-bar-container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  grid-column: auto !important;
  height: .25em;
  overflow: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }

.swal2-timer-progress-bar {
  width: 100%;
  height: .25em;
  background: rgba(0, 0, 0, 0.2); }

.swal2-image {
  max-width: 100%;
  margin: 2em auto 1em; }

.swal2-close {
  z-index: 2;
  align-items: center;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: -1.2em;
  padding: 0;
  overflow: hidden;
  transition: color .1s,box-shadow .1s;
  border: none;
  border-radius: 5px;
  background: 0 0;
  color: #ccc;
  font-family: serif;
  font-family: monospace;
  font-size: 2.5em;
  cursor: pointer;
  justify-self: end; }

.swal2-close:hover {
  transform: none;
  background: 0 0;
  color: #f27474; }

.swal2-close:focus {
  outline: 0;
  box-shadow: inset 0 0 0 3px rgba(100, 150, 200, 0.5); }

.swal2-close::-moz-focus-inner {
  border: 0; }

.swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em .3em;
  padding: 0;
  overflow: auto;
  color: #545454;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word; }

.swal2-checkbox, .swal2-file, .swal2-input, .swal2-radio, .swal2-select, .swal2-textarea {
  margin: 1em 2em 0; }

.swal2-file, .swal2-input, .swal2-textarea {
  box-sizing: border-box;
  width: auto;
  transition: border-color .1s,box-shadow .1s;
  border: 1px solid #d9d9d9;
  border-radius: .1875em;
  background: inherit;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06), 0 0 0 3px transparent;
  color: inherit;
  font-size: 1.125em; }

.swal2-file.swal2-inputerror, .swal2-input.swal2-inputerror, .swal2-textarea.swal2-inputerror {
  border-color: #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important; }

.swal2-file:focus, .swal2-input:focus, .swal2-textarea:focus {
  border: 1px solid #b4dbed;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06), 0 0 0 3px rgba(100, 150, 200, 0.5); }

.swal2-file::-moz-placeholder, .swal2-input::-moz-placeholder, .swal2-textarea::-moz-placeholder {
  color: #ccc; }

.swal2-file:-ms-input-placeholder, .swal2-input:-ms-input-placeholder, .swal2-textarea:-ms-input-placeholder {
  color: #ccc; }

.swal2-file::placeholder, .swal2-input::placeholder, .swal2-textarea::placeholder {
  color: #ccc; }

.swal2-range {
  margin: 1em 2em 0;
  background: #fff; }

.swal2-range input {
  width: 80%; }

.swal2-range output {
  width: 20%;
  color: inherit;
  font-weight: 600;
  text-align: center; }

.swal2-range input, .swal2-range output {
  height: 2.625em;
  padding: 0;
  font-size: 1.125em;
  line-height: 2.625em; }

.swal2-input {
  height: 2.625em;
  padding: 0 .75em; }

.swal2-file {
  width: 75%;
  margin-right: auto;
  margin-left: auto;
  background: inherit;
  font-size: 1.125em; }

.swal2-textarea {
  height: 6.75em;
  padding: .75em; }

.swal2-select {
  min-width: 50%;
  max-width: 100%;
  padding: .375em .625em;
  background: inherit;
  color: inherit;
  font-size: 1.125em; }

.swal2-checkbox, .swal2-radio {
  align-items: center;
  justify-content: center;
  background: #fff;
  color: inherit; }

.swal2-checkbox label, .swal2-radio label {
  margin: 0 .6em;
  font-size: 1.125em; }

.swal2-checkbox input, .swal2-radio input {
  flex-shrink: 0;
  margin: 0 .4em; }

.swal2-input-label {
  display: flex;
  justify-content: center;
  margin: 1em auto 0; }

.swal2-validation-message {
  align-items: center;
  justify-content: center;
  margin: 1em 0 0;
  padding: .625em;
  overflow: hidden;
  background: #f0f0f0;
  color: #666;
  font-size: 1em;
  font-weight: 300; }

.swal2-validation-message::before {
  content: "!";
  display: inline-block;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 .625em;
  border-radius: 50%;
  background-color: #f27474;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center; }

.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 2.5em auto .6em;
  border: .25em solid transparent;
  border-radius: 50%;
  border-color: #000;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 3.75em; }

.swal2-icon.swal2-error {
  border-color: #f27474;
  color: #f27474; }

.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1; }

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: .3125em;
  border-radius: .125em;
  background-color: #f27474; }

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 1.0625em;
  transform: rotate(45deg); }

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 1em;
  transform: rotate(-45deg); }

.swal2-icon.swal2-error.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
  -webkit-animation: swal2-animate-error-x-mark .5s;
  animation: swal2-animate-error-x-mark .5s; }

.swal2-icon.swal2-warning {
  border-color: #facea8;
  color: #f8bb86; }

.swal2-icon.swal2-warning.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-warning.swal2-icon-show .swal2-icon-content {
  -webkit-animation: swal2-animate-i-mark .5s;
  animation: swal2-animate-i-mark .5s; }

.swal2-icon.swal2-info {
  border-color: #9de0f6;
  color: #3fc3ee; }

.swal2-icon.swal2-info.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-info.swal2-icon-show .swal2-icon-content {
  -webkit-animation: swal2-animate-i-mark .8s;
  animation: swal2-animate-i-mark .8s; }

.swal2-icon.swal2-question {
  border-color: #c9dae1;
  color: #87adbd; }

.swal2-icon.swal2-question.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-question.swal2-icon-show .swal2-icon-content {
  -webkit-animation: swal2-animate-question-mark .8s;
  animation: swal2-animate-question-mark .8s; }

.swal2-icon.swal2-success {
  border-color: #a5dc86;
  color: #a5dc86; }

.swal2-icon.swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%; }

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em; }

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0; }

.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -.25em;
  left: -.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(165, 220, 134, 0.3);
  border-radius: 50%; }

.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  z-index: 1;
  top: .5em;
  left: 1.625em;
  width: .4375em;
  height: 5.625em;
  transform: rotate(-45deg); }

.swal2-icon.swal2-success [class^=swal2-success-line] {
  display: block;
  position: absolute;
  z-index: 2;
  height: .3125em;
  border-radius: .125em;
  background-color: #a5dc86; }

.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  top: 2.875em;
  left: .8125em;
  width: 1.5625em;
  transform: rotate(45deg); }

.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
  top: 2.375em;
  right: .5em;
  width: 2.9375em;
  transform: rotate(-45deg); }

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
  -webkit-animation: swal2-animate-success-line-tip .75s;
  animation: swal2-animate-success-line-tip .75s; }

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
  -webkit-animation: swal2-animate-success-line-long .75s;
  animation: swal2-animate-success-line-long .75s; }

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  -webkit-animation: swal2-rotate-success-circular-line 4.25s ease-in;
  animation: swal2-rotate-success-circular-line 4.25s ease-in; }

.swal2-progress-steps {
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  margin: 1.25em auto;
  padding: 0;
  background: inherit;
  font-weight: 600; }

.swal2-progress-steps li {
  display: inline-block;
  position: relative; }

.swal2-progress-steps .swal2-progress-step {
  z-index: 20;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background: #2778c4;
  color: #fff;
  line-height: 2em;
  text-align: center; }

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #2778c4; }

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
  background: #add8e6;
  color: #fff; }

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #add8e6; }

.swal2-progress-steps .swal2-progress-step-line {
  z-index: 10;
  flex-shrink: 0;
  width: 2.5em;
  height: .4em;
  margin: 0 -1px;
  background: #2778c4; }

[class^=swal2] {
  -webkit-tap-highlight-color: transparent; }

.swal2-show {
  -webkit-animation: swal2-show .3s;
  animation: swal2-show .3s; }

.swal2-hide {
  -webkit-animation: swal2-hide .15s forwards;
  animation: swal2-hide .15s forwards; }

.swal2-noanimation {
  transition: none; }

.swal2-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

.swal2-rtl .swal2-close {
  margin-right: initial;
  margin-left: 0; }

.swal2-rtl .swal2-timer-progress-bar {
  right: 0;
  left: auto; }

@-webkit-keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg); }
  33% {
    transform: translateY(0) rotateZ(-2deg); }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg); }
  100% {
    transform: translateY(0) rotateZ(0); } }

@keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg); }
  33% {
    transform: translateY(0) rotateZ(-2deg); }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg); }
  100% {
    transform: translateY(0) rotateZ(0); } }

@-webkit-keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0; } }

@keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0; } }

@-webkit-keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: .5625em;
    left: .0625em;
    width: 0; }
  54% {
    top: .125em;
    left: .125em;
    width: 0; }
  70% {
    top: .625em;
    left: -.25em;
    width: 1.625em; }
  84% {
    top: 1.0625em;
    left: .75em;
    width: .5em; }
  100% {
    top: 1.125em;
    left: .1875em;
    width: .75em; } }

@keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: .5625em;
    left: .0625em;
    width: 0; }
  54% {
    top: .125em;
    left: .125em;
    width: 0; }
  70% {
    top: .625em;
    left: -.25em;
    width: 1.625em; }
  84% {
    top: 1.0625em;
    left: .75em;
    width: .5em; }
  100% {
    top: 1.125em;
    left: .1875em;
    width: .75em; } }

@-webkit-keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0; }
  65% {
    top: 1.25em;
    right: .9375em;
    width: 0; }
  84% {
    top: .9375em;
    right: 0;
    width: 1.125em; }
  100% {
    top: .9375em;
    right: .1875em;
    width: 1.375em; } }

@keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0; }
  65% {
    top: 1.25em;
    right: .9375em;
    width: 0; }
  84% {
    top: .9375em;
    right: 0;
    width: 1.125em; }
  100% {
    top: .9375em;
    right: .1875em;
    width: 1.375em; } }

@-webkit-keyframes swal2-show {
  0% {
    transform: scale(0.7); }
  45% {
    transform: scale(1.05); }
  80% {
    transform: scale(0.95); }
  100% {
    transform: scale(1); } }

@keyframes swal2-show {
  0% {
    transform: scale(0.7); }
  45% {
    transform: scale(1.05); }
  80% {
    transform: scale(0.95); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0.5);
    opacity: 0; } }

@keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0.5);
    opacity: 0; } }

@-webkit-keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0; }
  54% {
    top: 1.0625em;
    left: .125em;
    width: 0; }
  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em; }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em; }
  100% {
    top: 2.8125em;
    left: .8125em;
    width: 1.5625em; } }

@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0; }
  54% {
    top: 1.0625em;
    left: .125em;
    width: 0; }
  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em; }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em; }
  100% {
    top: 2.8125em;
    left: .8125em;
    width: 1.5625em; } }

@-webkit-keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em; }
  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em; } }

@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em; }
  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em; } }

@-webkit-keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg); } }

@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg); } }

@-webkit-keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  80% {
    margin-top: -.375em;
    transform: scale(1.15); }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1; } }

@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  80% {
    margin-top: -.375em;
    transform: scale(1.15); }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

@keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

@-webkit-keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes swal2-animate-question-mark {
  0% {
    transform: rotateY(-360deg); }
  100% {
    transform: rotateY(0); } }

@keyframes swal2-animate-question-mark {
  0% {
    transform: rotateY(-360deg); }
  100% {
    transform: rotateY(0); } }

@-webkit-keyframes swal2-animate-i-mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0; }
  25% {
    transform: rotateZ(-25deg);
    opacity: .4; }
  50% {
    transform: rotateZ(15deg);
    opacity: .8; }
  75% {
    transform: rotateZ(-5deg);
    opacity: 1; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

@keyframes swal2-animate-i-mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0; }
  25% {
    transform: rotateZ(-25deg);
    opacity: .4; }
  50% {
    transform: rotateZ(15deg);
    opacity: .8; }
  75% {
    transform: rotateZ(-5deg);
    opacity: 1; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden; }

body.swal2-height-auto {
  height: auto !important; }

body.swal2-no-backdrop .swal2-container {
  background-color: transparent !important;
  pointer-events: none; }

body.swal2-no-backdrop .swal2-container .swal2-popup {
  pointer-events: all; }

body.swal2-no-backdrop .swal2-container .swal2-modal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }

@media print {
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: scroll !important; }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) > [aria-hidden=true] {
    display: none; }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) .swal2-container {
    position: static !important; } }

body.swal2-toast-shown .swal2-container {
  box-sizing: border-box;
  width: 360px;
  max-width: 100%;
  background-color: transparent;
  pointer-events: none; }

body.swal2-toast-shown .swal2-container.swal2-top {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%); }

body.swal2-toast-shown .swal2-container.swal2-top-end, body.swal2-toast-shown .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto; }

body.swal2-toast-shown .swal2-container.swal2-top-left, body.swal2-toast-shown .swal2-container.swal2-top-start {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0; }

body.swal2-toast-shown .swal2-container.swal2-center-left, body.swal2-toast-shown .swal2-container.swal2-center-start {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  transform: translateY(-50%); }

body.swal2-toast-shown .swal2-container.swal2-center {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%); }

body.swal2-toast-shown .swal2-container.swal2-center-end, body.swal2-toast-shown .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateY(-50%); }

body.swal2-toast-shown .swal2-container.swal2-bottom-left, body.swal2-toast-shown .swal2-container.swal2-bottom-start {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0; }

body.swal2-toast-shown .swal2-container.swal2-bottom {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); }

body.swal2-toast-shown .swal2-container.swal2-bottom-end, body.swal2-toast-shown .swal2-container.swal2-bottom-right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto; }

.ss-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  float: left; }

.ss-content {
  height: 100%;
  width: calc(100% + 18px);
  padding: 0 0 0 0;
  position: relative;
  overflow-x: auto;
  overflow-y: scroll;
  box-sizing: border-box; }

.ss-content.rtl {
  width: calc(100% + 18px);
  right: auto; }

.ss-scroll {
  position: relative;
  background: rgba(0, 0, 0, 0.1);
  width: 9px;
  border-radius: 4px;
  top: 0;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.25s linear; }

.ss-hidden {
  display: none; }

.ss-container:hover .ss-scroll,
.ss-container:active .ss-scroll {
  opacity: 1; }

.ss-grabbed {
  -o-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none; }

* {
  user-select: none; }

input {
  user-select: text !important; }

body {
  min-height: 100vh;
  margin: 0;
  color: #282828;
  font-family: 'Jost', sans-serif;
  display: flex;
  flex-direction: column;
  position: relative; }
  body.opened {
    overflow: hidden; }

main {
  position: relative;
  flex: 1 0 auto; }

.text {
  font-family: 'Montserrat', sans-serif; }

p, ol, ul, h1, h2, h3, button, form {
  margin: 0;
  padding: 0; }

button, input, textarea {
  font-family: inherit; }
  button:focus, input:focus, textarea:focus {
    outline: none; }

input {
  min-width: 0;
  font-size: inherit;
  appearance: none; }

ul, li {
  list-style-type: none; }

input::-webkit-input-placeholder {
  font-size: 15px;
  line-height: 18px;
  color: #999999; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type=number] {
  -webkit-appearance: none;
  -moz-appearance: textfield; }

@media (max-width: 1650px) {
  body {
    zoom: .8; } }
  @media (max-width: 1650px) and (max-width: 991px) {
    body {
      zoom: 1; } }

a {
  color: #1F2026;
  text-decoration: none;
  transition: color 0.3s; }

svg {
  vertical-align: middle;
  transition: fill 0.3s; }

picture {
  display: block; }

img {
  display: block; }

source {
  display: none; }

*, ::before, ::after {
  box-sizing: border-box; }

:focus {
  outline: none; }

.container {
  max-width: 1650px;
  margin: 0 auto;
  padding: 0 15px; }
  @media (max-width: 1199px) {
    .container {
      padding: 0 20px; } }

._576_d {
  display: none; }
  @media (max-width: 575px) {
    ._576_d {
      display: block; } }

._576_h {
  display: block; }
  @media (max-width: 575px) {
    ._576_h {
      display: none; } }

.d-flex {
  display: flex; }

.w100 {
  width: 100%; }

.ai-center {
  align-items: center; }

.jc-sb {
  justify-content: space-between; }

em {
  font-style: normal;
  color: #E5A701; }

.loading {
  filter: blur(3px);
  pointer-events: none;
  user-select: none; }

.d-flex-row {
  display: flex;
  flex-direction: row; }

.base__title {
  font-size: 32px;
  line-height: 46px;
  font-weight: 500;
  color: #1F2026;
  text-align: center;
  text-transform: uppercase; }
  .base__title--inverse {
    color: #fff; }
  @media (max-width: 1650px) {
    .base__title {
      font-size: 28px;
      line-height: 40px; } }
  @media (max-width: 1650px) and (max-width: 1199px) {
    .base__title {
      font-size: 24px;
      line-height: 30px; } }
  @media (max-width: 1650px) and (max-width: 1199px) and (max-width: 991px) {
    .base__title {
      font-size: 20px;
      line-height: 26px; } }
  @media (max-width: 1650px) and (max-width: 1199px) and (max-width: 991px) and (max-width: 575px) {
    .base__title {
      font-size: 16px;
      line-height: 16px; } }

.breadcrumbs {
  padding: 40px 0; }
  @media (max-width: 991px) {
    .breadcrumbs {
      padding: 20px 0; } }
  .breadcrumbs-item {
    font-size: 18px;
    line-height: 20px;
    font-weight: 300;
    position: relative;
    margin-right: 20px;
    color: #1F2026; }
    @media (max-width: 991px) {
      .breadcrumbs-item {
        font-size: 12px;
        line-height: 16px; } }
    .breadcrumbs-item:last-child {
      background: linear-gradient(90deg, #40BAEB 0%, #4066EB 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
    .breadcrumbs-item:not(:last-child):hover {
      color: #1F2026; }
      .breadcrumbs-item:not(:last-child):hover span > span {
        text-decoration: underline; }
      .breadcrumbs-item:not(:last-child):hover:after {
        background: #1F2026;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }
    .breadcrumbs-item:not(:last-child):after {
      top: 0;
      left: 100%;
      position: absolute;
      width: 20px;
      height: 100%;
      display: flex;
      align-items: center;
      content: " / ";
      color: #1F2026;
      justify-content: center; }
  .breadcrumbs-back {
    display: block;
    margin: 15px 0 0;
    padding: 10px 0; }
    .breadcrumbs-back:hover svg {
      fill: #E5A701; }
    .breadcrumbs-back:hover span {
      color: #E5A701; }
    .breadcrumbs-back svg {
      width: 16px;
      height: 10px;
      fill: #282828;
      margin-right: 5px;
      transition: fill 0.3s; }
    .breadcrumbs-back span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #282828;
      transition: color 0.3s; }

.swiper {
  transition: opacity 0.3s;
  opacity: 1; }
  .swiper-container-free-mode .swiper-wrapper {
    transition-timing-function: linear; }
  .swiper-init {
    opacity: 0; }
  .swiper-nav {
    cursor: pointer; }
    .swiper-nav--next svg {
      transform: rotate(180deg); }
    .swiper-nav svg {
      width: 36px;
      height: 25px;
      fill: #999999;
      transition: fill 0.3s; }
    .swiper-nav:hover svg {
      fill: #282828; }
  .swiper-pagination {
    z-index: 1; }

.swiper-button-disabled {
  opacity: .6;
  pointer-events: none; }

.no-photo {
  background-image: url("../images/placeholder.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.btn {
  transform: skewX(-15deg);
  max-width: 270px;
  min-width: 270px;
  user-select: none;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  background: #1F2026;
  border-radius: 5px;
  border: none;
  position: relative;
  transition: color 0.3s, background-color 0.3s; }
  @media (max-width: 1440px) {
    .btn {
      height: 45px; } }
  @media (max-width: 991px) {
    .btn {
      height: 40px; } }
  .btn span {
    transform: skewX(15deg);
    font-size: 18px;
    line-height: 22px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: #fff; }
    @media (max-width: 1440px) {
      .btn span {
        font-size: 16px;
        line-height: 20px; } }
  @media (max-width: 575px) {
    .btn {
      width: calc(100% - 12px);
      margin-left: 6px; } }
  .btn:hover .btn--inner {
    background: linear-gradient(90deg, #40BAEB 0%, #4066EB 100%); }
  .btn--white {
    background: inherit;
    border: 1px solid #fff; }
    .btn--white:hover {
      background: #fff; }
      .btn--white:hover span {
        color: #1F2026; }
  .btn--gradient {
    background: linear-gradient(90deg, #40BAEB 0%, #4066EB 100%); }
    .btn--gradient:hover {
      background: linear-gradient(90deg, #7ED3F5 0%, #8FA5F2 100%); }
  .btn--inverse {
    background: #fff;
    border: 1px solid #fff; }
    .btn--inverse span {
      color: #1F2026; }
    .btn--inverse:hover {
      background: transparent; }
      .btn--inverse:hover span {
        color: #fff; }
  @media (max-width: 575px) {
    .btn--mw100 {
      width: 100%;
      max-width: unset; } }
  .btn--inner {
    position: absolute;
    top: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background: #2C2D34;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.3s, background-color 0.3s; }

.form-group {
  position: relative; }
  .form-group20 > .base-input {
    display: flex;
    margin: 20px 0; }
    .form-group20 > .base-input:first-child {
      margin: 0 0 20px; }
    .form-group20 > .base-input:last-child {
      margin: 20px 0 0; }
    @media (max-width: 991px) {
      .form-group20 > .base-input {
        margin: 10px 0 !important; }
        .form-group20 > .base-input:first-child {
          margin: 0 0 10px !important; }
        .form-group20 > .base-input:last-child {
          margin: 10px 0 0 !important; } }
  .form-group15 > .base-input {
    margin: 15px 0; }
    .form-group15 > .base-input:first-child {
      margin: 0 0 15px; }
    .form-group15 > .base-input:last-child {
      margin: 15px 0 0; }
    @media (max-width: 991px) {
      .form-group15 > .base-input {
        margin: 10px 0 !important; }
        .form-group15 > .base-input:first-child {
          margin: 0 0 10px !important; }
        .form-group15 > .base-input:last-child {
          margin: 10px 0 0 !important; } }
  .form-group__error {
    display: none;
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FB474E; }
    @media (max-width: 1199px) {
      .form-group__error {
        font-weight: 400;
        font-size: 12px;
        line-height: 142%;
        text-align: center;
        color: #FB474E;
        top: unset;
        bottom: calc(100% + 5px); } }
  .form-group.error .js-base-input {
    border: 1px solid #FB474E !important; }
  .form-group.error .form-group__error {
    display: block; }

.base-input {
  border: 1px solid #8C8E98;
  background: white;
  border-radius: 5px;
  overflow: hidden;
  transform: skewX(-15deg);
  width: 100%;
  height: 50px; }
  .base-input--borderC0 {
    border: 1px solid #C0C0C0; }
  @media (max-width: 1440px) {
    .base-input {
      height: 45px; } }
  @media (max-width: 1440px) and (max-width: 991px) {
    .base-input {
      height: 40px; } }
  .base-input:has(.base-input__input:focus) {
    border: 1px solid #1F2026; }
  .base-input:has(.base-input__input:focus) .base-input__input::placeholder {
    opacity: 0; }
  .base-input--center .base-input__input {
    text-align: center; }
  .base-input__input {
    color: #1F2026;
    width: 100%;
    height: 100%;
    appearance: none;
    border: none;
    padding: 0 30px;
    transform: skewX(15deg); }
    .base-input__input, .base-input__input::placeholder {
      font-family: 'Montserrat', sans-serif;
      font-size: 18px;
      line-height: 22px;
      font-weight: 400; }
      @media (max-width: 991px) {
        .base-input__input, .base-input__input::placeholder {
          font-size: 16px;
          line-height: 20px; } }
    .base-input__input::placeholder {
      color: #8C8E98; }
    @media (max-width: 991px) {
      .base-input__input {
        text-align: center; } }

.theme-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .theme-success svg {
    width: 36px;
    height: 28px;
    fill: #14A356;
    margin: 0 0 25px; }
  .theme-success span {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #333333; }
    @media (max-width: 991px) {
      .theme-success span {
        font-size: 24px;
        line-height: 28px; } }

.base-modal {
  display: none;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  position: relative;
  width: calc(0.09375 * 100vw + 370px);
  padding: 60px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); }
  .base-modal.show {
    display: flex; }
  .base-modal__filter {
    width: 100vw; }
  .base-modal__close {
    position: absolute;
    width: calc(0.02125 * 100vw + 33.2px);
    height: calc(0.02125 * 100vw + 33.2px);
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    @media (max-width: 1199px) {
      .base-modal__close > svg {
        height: 10px;
        width: 10px; } }
    .base-modal__close:hover rect {
      fill: #1F2026; }
  .base-modal__row {
    display: flex;
    justify-content: space-between; }
    .base-modal__row > div {
      width: calc(50% - 10px); }
    @media (max-width: 1199px) {
      .base-modal__row {
        flex-direction: column;
        justify-content: unset; }
        .base-modal__row > div {
          width: 100%; }
          .base-modal__row > div:last-child {
            margin-top: 10px; } }
  .base-modal-request {
    width: calc(0.26625 * 100vw + 514.8px); }
    .base-modal-request__bottom {
      margin-top: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%; }
      .base-modal-request__bottom .base-modal-privacy {
        border: none;
        margin: 0;
        max-width: 368px;
        padding: 0; }
        .base-modal-request__bottom .base-modal-privacy__text {
          text-align: left; }
      @media (max-width: 575px) {
        .base-modal-request__bottom {
          margin-top: 10px;
          flex-direction: column-reverse; }
          .base-modal-request__bottom .base-modal-privacy {
            border: none;
            margin: 15px 0 0;
            max-width: 195px;
            padding: 0; }
            .base-modal-request__bottom .base-modal-privacy__text {
              text-align: center; } }
    .base-modal-request__btn {
      margin: 0 0 0 21px !important; }
      @media (max-width: 575px) {
        .base-modal-request__btn {
          margin: 0 !important; } }
    .base-modal-request__textarea {
      margin-top: 20px;
      font-size: 18px;
      line-height: 22px;
      height: 305px;
      padding: 14px 30px;
      border: 1px solid #8C8E99;
      border-radius: 10px;
      transform: none; }
      @media (max-width: 1199px) {
        .base-modal-request__textarea {
          margin-top: 10px; } }
  .base-modal-order__title {
    font-weight: 500; }
  .base-modal__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 100px;
    height: 100%;
    background: rgba(0, 0, 0, 0.45);
    display: none;
    justify-content: center;
    z-index: 9999;
    overflow-y: scroll; }
  .base-modal-success {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; }
    .base-modal-success__title {
      background: linear-gradient(90deg, #40BAEB 0%, #4066EB 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-top: 40px; }
  @media (max-width: 575px) {
    .base-modal {
      width: 100% !important; } }
  @media (max-width: 1440px) {
    .base-modal {
      padding: 50px 30px 40px; } }
  @media (max-width: 1440px) and (max-width: 767px) {
    .base-modal {
      padding: 45px 20px 35px; } }
  .base-modal__title {
    font-weight: 600;
    font-size: calc(0.01 * 100vw + 12.8px);
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #1F2026; }
  .base-modal__description {
    margin-top: 20px;
    font-weight: 400;
    font-size: calc(0.005 * 100vw + 10.4px);
    line-height: calc(0.00813 * 100vw + 13.4px);
    text-align: center;
    color: #5D5E62; }
    @media (max-width: 1199px) {
      .base-modal__description {
        margin-top: 15px; } }
    @media (max-width: 1199px) {
      .base-modal__description {
        color: #404040; } }
  .base-modal__group {
    margin-top: 40px;
    width: 100%; }
    .base-modal__group--33 {
      margin-top: 33px; }
    @media (max-width: 575px) {
      .base-modal__group {
        margin-top: 30px; } }
  .base-modal-privacy {
    margin-top: 30px;
    border-top: 1px solid #D9D9D9;
    padding-top: 12px;
    display: flex;
    justify-content: center; }
    @media (max-width: 1199px) {
      .base-modal-privacy {
        margin-top: 15px; } }
    .base-modal-privacy__text {
      font-weight: 500;
      font-size: calc(0.0025 * 100vw + 9.2px);
      line-height: 17px;
      text-align: center;
      color: #1F2026; }
      .base-modal-privacy__text > a {
        background: linear-gradient(90deg, #40BAEB 0%, #4066EB 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-decoration: underline; }
  .base-modal__btn {
    margin-top: 20px;
    appearance: none;
    outline: none;
    border: none; }

.js-modal-opened {
  overflow: hidden; }
  .js-modal-opened .base-modal__bg {
    display: flex; }

.header {
  background: #1F2026; }
  .header-top {
    height: 56px;
    background: #2C2D34;
    display: flex;
    align-items: center; }
    .header-top__left {
      display: flex;
      align-items: center;
      height: 100%; }
    .header-top__contacts, .header-top__social {
      display: flex;
      align-items: center; }
    .header-top__social {
      display: flex;
      margin-left: 70px; }
      @media (max-width: 1440px) {
        .header-top__social {
          margin-left: 40px; } }
    .header-top__nav {
      display: flex;
      align-items: center; }
    .header-top-nav {
      display: block;
      font-size: 18px;
      line-height: 26px;
      font-weight: 300;
      color: #B5B7C5; }
      .header-top-nav:not(:last-child) {
        margin-right: 35px; }
      @media (max-width: 1650px) {
        .header-top-nav {
          font-size: 16px;
          line-height: 24px; } }
      @media (max-width: 1440px) {
        .header-top-nav {
          font-size: 14px;
          line-height: 22px; } }
      .header-top-nav:hover {
        text-decoration: underline;
        color: #fff; }
    .header-top-contact {
      display: flex;
      align-items: center; }
      .header-top-contact:not(:last-child) {
        margin-right: 70px; }
      .header-top-contact__icon {
        height: 25px;
        width: 25px;
        margin-right: 20px; }
      .header-top-contact__text {
        font-size: 18px;
        line-height: 26px;
        font-weight: 300;
        color: #B5B7C5; }
        @media (max-width: 1650px) {
          .header-top-contact__text {
            font-size: 16px;
            line-height: 24px; } }
        @media (max-width: 1440px) {
          .header-top-contact__text {
            font-size: 14px;
            line-height: 22px; } }
    .header-top-social {
      display: flex;
      align-items: center;
      justify-content: center; }
      .header-top-social:not(:last-child) {
        margin-right: 35px; }
      .header-top-social .vk {
        width: 32px;
        height: 20px; }
      .header-top-social .inst {
        width: 25px;
        height: 25px; }
      @media (max-width: 1440px) {
        .header-top-social {
          margin-right: 15px; }
          .header-top-social .vk {
            transform: scale(0.8); }
          .header-top-social .inst {
            transform: scale(0.8); } }
  @media (max-width: 1440px) and (max-width: 767px) {
    .header-top-social .vk, .header-top-social .inst {
      transform: none; } }
      .header-top-social:hover path {
        fill: #fff; }
  .header-main {
    display: flex;
    align-items: center;
    height: 100px; }
    .header-main-logo {
      position: relative;
      height: 80px;
      margin: 20px 0;
      display: block;
      width: 143.57px; }
      .header-main-logo__img {
        width: 100%;
        height: 100%; }
      .header-main-logo__text {
        font-size: 15px;
        line-height: 22px;
        font-weight: 400;
        left: -30%;
        width: 150%;
        position: absolute;
        top: 92%;
        color: #fff;
        text-align: center; }
        @media (max-width: 1440px) {
          .header-main-logo__text {
            font-size: 12px; } }
  @media (max-width: 1440px) and (max-width: 1199px) {
    .header-main-logo__text {
      display: none; } }
      @media (max-width: 1650px) {
        .header-main-logo {
          margin: 15px 0; } }
      @media (max-width: 1440px) {
        .header-main-logo {
          height: 60px;
          width: 107.6775px; } }
    .header-main-search {
      margin-left: 90px;
      flex: 1;
      height: 50px;
      background: linear-gradient(90deg, #40BAEB 0%, #4066EB 66.88%, #40BAEB 100%);
      border-radius: 5px;
      transform: skewX(-14deg);
      position: relative; }
      @media (max-width: 1440px) {
        .header-main-search {
          margin-left: 60px;
          height: 40px; } }
      @media (max-width: 1199px) {
        .header-main-search {
          margin-left: 0;
          flex: none;
          width: 100%; } }
      .header-main-search__inner {
        position: absolute;
        left: 1px;
        top: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background: #2C2D34;
        border-radius: 5px; }
      .header-main-search__input {
        transform: skewX(14deg);
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        color: #fff;
        padding: 0 100px 0 30px; }
        @media (max-width: 1440px) {
          .header-main-search__input {
            font-size: 16px;
            line-height: 20px; } }
        .header-main-search__input::placeholder {
          font-size: 18px;
          line-height: 22px;
          font-weight: 400;
          color: #B5B7C5; }
          @media (max-width: 1440px) {
            .header-main-search__input::placeholder {
              font-size: 16px;
              line-height: 20px; } }
      .header-main-search-btn {
        z-index: 1;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 72px;
        border-radius: 5px;
        height: 50px;
        background: linear-gradient(90deg, #40BAEB 0%, #4066EB 100%); }
        @media (max-width: 1440px) {
          .header-main-search-btn {
            height: 40px;
            width: 60px; } }
        .header-main-search-btn__icon {
          transform: skewX(14deg);
          width: 25px;
          height: 25px;
          fill: #fff; }
          @media (max-width: 1440px) {
            .header-main-search-btn__icon {
              width: 20px;
              height: 20px; } }
    .header-main-cart {
      position: relative;
      cursor: pointer;
      padding-top: 14px;
      margin-left: 40px; }
      .header-main-cart__container {
        appearance: none !important;
        overflow: auto !important;
        display: flex;
        flex-direction: column;
        align-items: center; }
      .header-main-cart__inner {
        position: relative; }
      .header-main-cart__icon {
        width: 21px;
        height: 23px;
        fill: transparent; }
      .header-main-cart__count {
        position: absolute;
        bottom: -11px;
        right: -13px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #383A44;
        border-radius: 9.5px;
        padding: 1px 9px;
        transition: color 0.3s, background-color 0.3s;
        font-size: 12px;
        line-height: 17px;
        font-weight: 300;
        color: #fff; }
      .header-main-cart__text {
        margin-top: 7px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        color: #fff; }
      .header-main-cart-dropdown {
        background: transparent;
        display: none;
        position: absolute;
        z-index: 4;
        right: -16px;
        top: 100%; }
        .header-main-cart-dropdown__inner {
          cursor: default;
          margin-top: 23px;
          background: #fff;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
          min-width: 363px;
          max-width: 363px; }
        .header-main-cart-dropdown__header {
          position: relative;
          padding: 10px 20px;
          width: 100%;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
          background: #ECECEC;
          display: flex;
          align-items: center; }
          .header-main-cart-dropdown__header:before {
            content: "";
            position: absolute;
            right: 30px;
            top: -10px;
            width: 21px;
            height: 21px;
            background: #ECECEC;
            transform: rotate(45deg) skew(-8deg, -8deg); }
        .header-main-cart-dropdown__footer {
          padding: 16px 20px;
          display: flex;
          justify-content: space-between;
          align-items: end; }
        .header-main-cart-dropdown__content {
          height: 293px;
          max-height: 293px;
          margin: 20px;
          position: relative; }
          .header-main-cart-dropdown__content .ss-scroll {
            right: -15px !important;
            width: 5px;
            background: #2C2D34;
            position: absolute;
            border-radius: 2px !important;
            opacity: 1 !important; }
        .header-main-cart-dropdown__title, .header-main-cart-dropdown__count {
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;
          color: #707070; }
          @media (max-width: 1650px) {
            .header-main-cart-dropdown__title, .header-main-cart-dropdown__count {
              font-size: 16px;
              line-height: 24px; } }
          @media (max-width: 1440px) {
            .header-main-cart-dropdown__title, .header-main-cart-dropdown__count {
              font-size: 14px;
              line-height: 22px; } }
        .header-main-cart-dropdown__count {
          color: #4066EB; }
        .header-main-cart-dropdown__total_text {
          font-size: 20px;
          line-height: 29px;
          font-weight: 300;
          color: #1F2026; }
          @media (max-width: 1650px) {
            .header-main-cart-dropdown__total_text {
              font-size: 18px;
              line-height: 26px; } }
          @media (max-width: 1440px) {
            .header-main-cart-dropdown__total_text {
              font-size: 16px;
              line-height: 22px; } }
        .header-main-cart-dropdown__total {
          font-size: 24px;
          line-height: 35px;
          font-weight: 500;
          color: #1F2026; }
      .header-main-cart-item {
        display: flex;
        align-items: center; }
        .header-main-cart-item__img {
          width: 42px;
          height: 80px;
          object-fit: contain; }
        .header-main-cart-item-content {
          margin-left: 20px;
          display: flex;
          align-items: center;
          flex: 1; }
          .header-main-cart-item-content__left {
            flex: 1; }
        .header-main-cart-item__title {
          display: block;
          font-size: 16px;
          line-height: 23px;
          font-weight: 400;
          color: #393D39; }
          @media (max-width: 1440px) {
            .header-main-cart-item__title {
              font-size: 14px;
              line-height: 20px; } }
          .header-main-cart-item__title:hover {
            color: #393D39; }
        .header-main-cart-item__count {
          font-size: 16px;
          line-height: 23px;
          font-weight: 400;
          color: #A4A4A4; }
          @media (max-width: 1440px) {
            .header-main-cart-item__count {
              font-size: 14px;
              line-height: 20px; } }
        .header-main-cart-item__total {
          margin-left: 11px;
          width: fit-content;
          min-width: fit-content;
          padding-right: 2px;
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;
          color: #1F2026; }
          @media (max-width: 1650px) {
            .header-main-cart-item__total {
              font-size: 16px;
              line-height: 24px; } }
          @media (max-width: 1440px) {
            .header-main-cart-item__total {
              font-size: 14px;
              line-height: 22px; } }
      .header-main-cart:hover .header-main-cart__count, .header-main-cart:not(.hide-dropdown) .header-main-cart__count {
        background: linear-gradient(90deg, #40BAEB 0%, #4066EB 100%); }
      .header-main-cart:not(.hide-dropdown):hover .header-main-cart-dropdown {
        display: block; }
    .header-main__right {
      width: fit-content;
      margin-left: 84px;
      display: flex;
      align-items: center; }
      @media (max-width: 1440px) {
        .header-main__right {
          margin-left: 60px; } }
    .header-main__recall {
      margin-left: 30px; }
      @media (max-width: 1199px) {
        .header-main__recall {
          margin-left: 0;
          width: 100%;
          margin-top: 22.5px; } }
      @media (max-width: 575px) {
        .header-main__recall {
          max-width: unset; } }
    .header-main__phone {
      font-size: 20px;
      line-height: 29px;
      font-weight: 500;
      color: #fff; }
      @media (max-width: 1650px) {
        .header-main__phone {
          font-size: 18px;
          line-height: 26px; } }
      @media (max-width: 1440px) {
        .header-main__phone {
          font-size: 16px;
          line-height: 22px; } }
      @media (max-width: 1199px) {
        .header-main__phone {
          display: block;
          margin-top: 17.5px;
          margin-bottom: 2.5px; } }
      .header-main__phone:hover {
        background: linear-gradient(90deg, #40BAEB 0%, #4066EB 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }
  .header-catalog {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .header-catalog__categories {
      display: flex;
      align-items: center; }
    .header-catalog-category, .header-catalog__all {
      padding: 25px 17.5px;
      display: block;
      font-size: 20px;
      line-height: 29px;
      font-weight: 400;
      user-select: none;
      text-transform: uppercase;
      transition: color 0.3s, background-color 0.3s;
      transition: background-color 0.3s;
      color: #fff;
      position: relative; }
      @media (max-width: 1440px) {
        .header-catalog-category, .header-catalog__all {
          padding: 20px 12.5px; } }
      @media (max-width: 1650px) {
        .header-catalog-category, .header-catalog__all {
          font-size: 18px;
          line-height: 26px; } }
      @media (max-width: 1440px) {
        .header-catalog-category, .header-catalog__all {
          font-size: 16px;
          line-height: 22px; } }
      .header-catalog-category:first-child, .header-catalog__all:first-child {
        padding: 25px 17.5px 25px 0; }
      .header-catalog-category:last-child, .header-catalog__all:last-child {
        padding: 25px 0 25px 17.5px; }
      .header-catalog-category:after, .header-catalog__all:after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        height: 4px;
        width: 0%;
        background: linear-gradient(90deg, #40BAEB 0%, #4066EB 100%);
        transition: width .2s, left .2s; }
      .header-catalog-category:hover, .header-catalog__all:hover {
        background: linear-gradient(90deg, #40BAEB 0%, #4066EB 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }
        .header-catalog-category:hover:after, .header-catalog__all:hover:after {
          width: 100%;
          left: 0%; }
    .header-catalog-category {
      position: relative; }
      .header-catalog-category__dropdown {
        position: absolute;
        appearance: none;
        top: 100%;
        left: 0;
        display: none;
        min-width: 100%;
        background: #f5f5f5;
        z-index: 2; }
      .header-catalog-category:hover > .header-catalog-category__dropdown {
        display: block;
        width: max-content; }
    .header-catalog-subcategory {
      font-size: 18px;
      line-height: 26px;
      display: flex;
      font-weight: 400;
      text-transform: none;
      padding: 10px 20px;
      min-width: max-content;
      width: 100%;
      z-index: 1;
      background: #1F2026;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
      @media (max-width: 1650px) {
        .header-catalog-subcategory {
          font-size: 16px;
          line-height: 24px; } }
      @media (max-width: 1440px) {
        .header-catalog-subcategory {
          font-size: 14px;
          line-height: 22px; } }
      .header-catalog-subcategory:first-child {
        padding: 20px 20px 10px; }
      .header-catalog-subcategory:last-child {
        padding: 10px 20px 20px; }
      .header-catalog-subcategory:only-child {
        padding: 20px; }
      .header-catalog-subcategory:hover {
        background: linear-gradient(90deg, #40BAEB 0%, #4066EB 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }
    .header-catalog__all {
      padding: 25px 17.5px !important;
      margin-right: -17.5px; }

@keyframes burger1 {
  from {
    top: 0; }
  50% {
    top: 7px; }
  55% {
    top: 7px; }
  to {
    top: 7px;
    transform: rotate(135deg); } }

@keyframes burger2 {
  from { }
  50% { }
  55% {
    opacity: 0; }
  to {
    opacity: 0; } }

@keyframes burger3 {
  from { }
  50% {
    bottom: 7px; }
  55% {
    bottom: 7px; }
  to {
    bottom: 7px;
    transform: rotate(-135deg); } }

.header-mobile {
  width: 100%;
  height: 70px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  position: relative; }
  @media (max-width: 767px) {
    .header-mobile {
      height: 50px; } }
  .header-mobile-logo {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    pointer-events: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .header-mobile-logo__inner {
      display: flex;
      flex-direction: column;
      align-items: center; }
    .header-mobile-logo__icon {
      display: block;
      pointer-events: auto; }
      .header-mobile-logo__icon > svg {
        width: 120px;
        height: 50px; }
        @media (max-width: 767px) {
          .header-mobile-logo__icon > svg {
            width: 60px;
            height: 25px; } }
    .header-mobile-logo__text {
      font-size: 16px;
      color: #fff; }
      @media (max-width: 767px) {
        .header-mobile-logo__text {
          font-size: 8px; } }
  .header-mobile-burger {
    display: flex;
    position: relative;
    z-index: 2;
    align-items: center; }
    .header-mobile-burger-icon {
      width: 22px;
      position: relative;
      height: 16px; }
      .header-mobile-burger-icon__row {
        width: 100%;
        height: 2px;
        background: #fff;
        border-radius: 10.5px;
        position: absolute;
        transition: top .3s, bottom .3s, rotate .3s; }
        .header-mobile-burger-icon__row:first-child {
          top: 0; }
        .header-mobile-burger-icon__row:nth-child(2) {
          top: 7px; }
        .header-mobile-burger-icon__row:last-child {
          bottom: 0; }
    .header-mobile-burger.active .header-mobile-burger-icon__row {
      animation-fill-mode: forwards !important; }
      .header-mobile-burger.active .header-mobile-burger-icon__row:first-child {
        animation: burger1 .6s ease; }
      .header-mobile-burger.active .header-mobile-burger-icon__row:nth-child(2) {
        animation: burger2 .6s ease; }
      .header-mobile-burger.active .header-mobile-burger-icon__row:last-child {
        animation: burger3 .6s ease; }
  .header-mobile__buttons {
    position: relative;
    z-index: 2; }
  .header-mobile-btn {
    width: 18px;
    height: 18px; }
    .header-mobile-btn:not(:last-child) {
      margin-right: 26px; }
    .header-mobile-btn .has-items {
      display: none;
      position: absolute;
      top: 0;
      right: -4px;
      width: 9px;
      border-radius: 50%;
      height: 9px;
      background: linear-gradient(90deg, #40BAEB 0%, #4066EB 100%); }
    .header-mobile-btn.cart {
      width: 17.35px;
      height: 19px; }
      .header-mobile-btn.cart.active > .has-items {
        display: block; }
  .header-mobile-menu {
    position: fixed;
    z-index: 55;
    overflow-y: scroll;
    background: #1F2026;
    padding: 20px;
    left: 0;
    display: none;
    width: 100%;
    top: 70px;
    height: calc(100vh - 70px); }
    .header-mobile-menu.active {
      display: block; }
    @media (max-width: 767px) {
      .header-mobile-menu {
        top: 50px;
        height: calc(100vh - 50px); } }
  .header-mobile__categories {
    width: 100%;
    margin-top: 17.5px; }
  .header-mobile-category__row {
    padding: 12.5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .header-mobile-category__title {
    font-size: 18px;
    line-height: 16px;
    font-weight: 500;
    color: white;
    display: block;
    padding-right: 30px;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .header-mobile-category__title {
        font-size: 14px;
        line-height: 14px; } }
  .header-mobile-category__icon {
    transform: rotate(-45deg);
    width: 15px;
    height: 15px;
    transition: transform 0.3s; }
    @media (max-width: 767px) {
      .header-mobile-category__icon {
        width: 11px;
        height: 11px; } }
    .header-mobile-category__icon-wrapper {
      display: flex;
      justify-content: flex-end;
      flex: 1; }
  .header-mobile-category__items {
    padding: 0 15px;
    display: none; }
  .header-mobile-category.active .header-mobile-category__title {
    color: #40BAEB !important; }
  .header-mobile-category.active .header-mobile-category__row {
    padding: 12.5px 0 10px; }
  .header-mobile-category.active .header-mobile-category__items {
    display: block; }
  .header-mobile-category.active .header-mobile-category__icon {
    transform: rotate(0); }
  .header-mobile-subcategory {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #fff;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: #fff;
    padding: 5px 0;
    display: block; }
  .header-mobile-footer {
    margin-top: 15px;
    width: 100%; }
    .header-mobile-footer__phone {
      margin-top: 17.5px; }
    .header-mobile-footer__info {
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      color: #b5b7c5;
      padding: 2.5px; }
    .header-mobile-footer-nav {
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      color: #b5b7c5;
      padding: 2.5px 0;
      display: block; }

#mobileHeader {
  position: sticky;
  top: 0;
  transition: top .5s;
  z-index: 2; }

.js-header-mobile-active {
  overflow: hidden; }

.footer {
  background: #1F2026;
  border-top: 2px solid #34363F; }
  .footer-main {
    padding: 65px 0 90px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between; }
    @media (max-width: 1650px) {
      .footer-main {
        padding: 65px 15px 90px; } }
    @media (max-width: 767px) {
      .footer-main {
        padding: 35px 15px 60px; } }
    @media (max-width: 575px) {
      .footer-main {
        padding: 25px 20px; } }
    .footer-main__logo {
      margin: -11px 0 15px; }
      @media (max-width: 767px) {
        .footer-main__logo {
          margin: 0; } }
  @media (max-width: 767px) and (max-width: 575px) {
    .footer-main__logo {
      height: 60px;
      margin-bottom: 20px; } }
    @media (max-width: 767px) {
      .footer-main-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-around; } }
  @media (max-width: 767px) and (max-width: 575px) {
    .footer-main-info {
      flex-direction: column;
      justify-content: unset; }
      .footer-main-info__container {
        width: 100%; } }
    .footer-main__info {
      font-size: 18px;
      line-height: 26px;
      padding: 5px 0;
      font-weight: 400;
      color: #8C8E98; }
      .footer-main__info.link {
        color: #b5b7c5;
        display: block;
        line-height: 22px;
        text-align: right;
        margin-top: 15px; }
        @media (max-width: 575px) {
          .footer-main__info.link {
            margin-top: 0; } }
      .footer-main__info:hover {
        color: #fff; }
      @media (max-width: 575px) {
        .footer-main__info {
          font-size: 14px;
          line-height: 20px; } }
    .footer-main__left {
      flex: 1;
      display: flex;
      justify-content: space-between; }
      @media (max-width: 1199px) {
        .footer-main__left {
          justify-content: space-around; } }
      @media (max-width: 767px) {
        .footer-main__left {
          flex-direction: column;
          align-items: center; } }
  .footer__phone {
    display: block;
    font-size: 28px;
    line-height: 40px;
    font-weight: 500;
    color: #fff;
    text-align: right; }
    @media (max-width: 1440px) {
      .footer__phone {
        font-size: 24px;
        line-height: 30px; } }
  @media (max-width: 1440px) and (max-width: 1199px) {
    .footer__phone {
      font-size: 22px;
      line-height: 26px; } }
    .footer__phone:hover {
      background: linear-gradient(90deg, #40BAEB 0%, #4066EB 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
  .footer-bottom {
    border-top: 2px solid #34363F; }
    .footer-bottom__inner {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      padding: 30px 0 25px; }
      @media (max-width: 1650px) {
        .footer-bottom__inner {
          padding: 20px 20px 15px; } }
  @media (max-width: 1650px) and (max-width: 767px) {
    .footer-bottom__inner {
      padding: 15px 20px; } }
    .footer-bottom__rights {
      font-weight: 300;
      font-size: calc(0.00375 * 100vw + 10.8px);
      line-height: 140%;
      color: #8C8E98; }
      @media (max-width: 991px) {
        .footer-bottom__rights {
          width: 50%; } }
  @media (max-width: 991px) and (max-width: 575px) {
    .footer-bottom__rights {
      width: 100%; } }
    .footer-bottom__privacy {
      font-weight: 500;
      font-size: calc(0.0025 * 100vw + 13.2px);
      line-height: 140%;
      color: #8C8E98;
      padding: 0 20px; }
      @media (max-width: 991px) {
        .footer-bottom__privacy {
          padding: 0;
          width: 50%;
          text-align: right; } }
  @media (max-width: 991px) and (max-width: 575px) {
    .footer-bottom__privacy {
      margin-top: 10px;
      text-align: left;
      display: block;
      width: 100%; } }
    .footer-bottom__placestart {
      margin-left: -3px;
      width: calc(0.04597 * 100vw + 127.74px);
      height: calc(0.00562 * 100vw + 15.2px); }
      @media (max-width: 991px) {
        .footer-bottom__placestart {
          margin-top: 10px; } }
      .footer-bottom__placestart > svg {
        width: 100%;
        height: 100%; }
  .footer__links {
    display: flex;
    margin-left: 30px;
    margin-top: -6px; }
    @media (max-width: 767px) {
      .footer__links {
        width: 100%;
        justify-content: space-around;
        margin-left: 0;
        margin-top: 30px; } }
  @media (max-width: 767px) and (max-width: 575px) {
    .footer__links {
      flex-direction: column;
      justify-content: unset; } }
  .footer__navigation:not(:first-child) {
    margin-left: 90px; }
    @media (max-width: 1440px) {
      .footer__navigation:not(:first-child) {
        margin-left: 30px; } }
    @media (max-width: 767px) {
      .footer__navigation:not(:first-child) {
        margin-left: 0; } }
    @media (max-width: 575px) {
      .footer__navigation:not(:first-child) {
        margin-top: 20px; } }
  .footer-nav {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    padding: 5px 0;
    display: block;
    color: #8C8E98; }
    .footer-nav:hover {
      color: #fff; }
  .footer-contacts {
    margin-left: 382px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    @media (max-width: 1650px) {
      .footer-contacts {
        margin-left: 170px; } }
    @media (max-width: 1440px) {
      .footer-contacts {
        margin-left: 90px; } }
    @media (max-width: 1199px) {
      .footer-contacts {
        margin-left: 0;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        flex-direction: row; } }
    @media (max-width: 575px) {
      .footer-contacts {
        flex-direction: column;
        align-items: flex-start; } }
    .footer-contacts__bottom {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-end; }
      @media (max-width: 1199px) {
        .footer-contacts__bottom {
          flex-flow: column-reverse;
          align-items: center; } }
      @media (max-width: 575px) {
        .footer-contacts__bottom {
          align-items: flex-start; } }
    .footer-contacts__left {
      display: flex;
      flex-direction: column;
      align-items: flex-end; }
      @media (max-width: 1199px) {
        .footer-contacts__left {
          align-items: center; } }
      @media (max-width: 575px) {
        .footer-contacts__left {
          align-items: flex-start;
          margin-top: 30px; } }
    .footer-contacts__social {
      margin-left: 0; }
      @media (max-width: 1199px) {
        .footer-contacts__social {
          margin-top: 20px; } }
  .footer__order {
    margin-top: 38px; }
    @media (max-width: 1199px) {
      .footer__order {
        margin-top: 0; } }

.search-line {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #FFD249;
  width: 100%;
  height: 45px;
  position: relative; }
  .search-line.error {
    border-color: #ff2828; }
  .search-line input {
    flex: 1 0 auto;
    border: none;
    height: 100%;
    padding: 0 15px; }
  .search-line button {
    border: none;
    width: 45px;
    height: 45px;
    position: relative;
    right: -1px; }
    .search-line button svg {
      width: 20px;
      height: 20px;
      stroke: #282828;
      fill: transparent; }
  .search-line__result {
    display: none;
    width: 100%;
    position: absolute;
    top: calc(100% + 1px);
    background-color: #fff;
    padding: 30px 20px; }
    .search-line__result.opened {
      display: block; }
    .search-line__result:empty {
      display: none !important; }
  .search-line__title {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #282828;
    margin: 0 0 10px; }
  .search-line__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0; }
    .search-line__item span {
      font-size: 15px;
      line-height: 18px;
      color: #282828;
      transition: color 0.3s; }
    .search-line__item svg {
      height: 12px;
      width: 8px;
      fill: #999999; }
    .search-line__item:hover span {
      color: #E5A701; }
  .search-line__extra {
    border-top: 1px solid #D0D0D0;
    padding: 15px 0 0;
    margin: 30px 0 0; }

.lg-group {
  *zoom: 1; }

.lg-group:before, .lg-group:after {
  display: table;
  content: "";
  line-height: 0; }

.lg-group:after {
  clear: both; }

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  outline: none;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }
  .lg-outer * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .lg-outer.lg-visible {
    opacity: 1; }
  .lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
    -webkit-transition-duration: inherit !important;
    transition-duration: inherit !important;
    -webkit-transition-timing-function: inherit !important;
    transition-timing-function: inherit !important; }
  .lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important;
    opacity: 1; }
  .lg-outer.lg-grab img.lg-object {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .lg-outer.lg-grabbing img.lg-object {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .lg-outer .lg {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%; }
  .lg-outer .lg-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap; }
  .lg-outer .lg-item {
    background: url("../images/loading.gif") no-repeat scroll center center transparent;
    display: none !important; }
  .lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
    display: inline-block !important; }
  .lg-outer.lg-css .lg-current {
    display: inline-block !important; }
  .lg-outer .lg-item, .lg-outer .lg-img-wrap {
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%; }
    .lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
      content: "";
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px; }
  .lg-outer .lg-img-wrap {
    position: absolute;
    padding: 0 5px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
  .lg-outer .lg-item.lg-complete {
    background-image: none; }
  .lg-outer .lg-item.lg-current {
    z-index: 1060; }
  .lg-outer .lg-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important; }
  .lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
    opacity: 0;
    -webkit-transition: opacity 0.15s ease 0s;
    -o-transition: opacity 0.15s ease 0s;
    transition: opacity 0.15s ease 0s; }
  .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
    opacity: 1; }
  .lg-outer .lg-empty-html {
    display: none; }
  .lg-outer.lg-hide-download #lg-download {
    display: none; }

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }
  .lg-backdrop.in {
    opacity: 0.9; }

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important; }

.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-fade .lg-item {
  opacity: 0; }
  .lg-css3.lg-fade .lg-item.lg-current {
    opacity: 1; }
  .lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
    -webkit-transition: opacity 0.1s ease 0s;
    -moz-transition: opacity 0.1s ease 0s;
    -o-transition: opacity 0.1s ease 0s;
    transition: opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
    left: -100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
    left: 100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    left: 0;
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

@font-face {
  font-family: 'lg';
  src: url("../fonts/lg/lg.ttf?22t19m") format("truetype"), url("../fonts/lg/lg.woff?22t19m") format("woff"), url("../fonts/lg/lg.svg?22t19m#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

.lg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'lg' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  outline: none;
  border: none;
  background-color: transparent; }
  .lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
    pointer-events: none;
    opacity: 0.5; }
  .lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    color: #FFF; }

.lg-actions .lg-next {
  right: 20px; }
  .lg-actions .lg-next:before {
    content: "\e095"; }

.lg-actions .lg-prev {
  left: 20px; }
  .lg-actions .lg-prev:after {
    content: "\e094"; }

@-webkit-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-moz-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-ms-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-webkit-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@-moz-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@-ms-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative; }

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative; }

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45); }
  .lg-toolbar .lg-icon {
    color: #999;
    cursor: pointer;
    float: right;
    font-size: 24px;
    height: 47px;
    line-height: 27px;
    padding: 10px 0;
    text-align: center;
    width: 50px;
    text-decoration: none !important;
    outline: medium none;
    background: none;
    border: none;
    box-shadow: none;
    -webkit-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    transition: color 0.2s linear; }
    .lg-toolbar .lg-icon:hover {
      color: #FFF; }
  .lg-toolbar .lg-close:after {
    content: "\e070"; }
  .lg-toolbar .lg-download:after {
    content: "\e0f2"; }

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080; }
  .lg-sub-html h4 {
    margin: 0;
    font-size: 13px;
    font-weight: bold; }
  .lg-sub-html p {
    font-size: 12px;
    margin: 5px 0 0; }

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle; }

.lg-toolbar, .lg-prev, .lg-next {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; }

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0); }

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0); }

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; }
  .lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important; }

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0); }

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px; }

.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px; }
  @media (min-width: 1025px) {
    .lg-outer .lg-thumb-item {
      -webkit-transition: border-color 0.25s ease;
      -o-transition: border-color 0.25s ease;
      transition: border-color 0.25s ease; } }
  .lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
    border-color: #a90707; }
  .lg-outer .lg-thumb-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px; }

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0; }

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease; }

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px; }

.lg-outer .lg-toggle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
  outline: medium none;
  border: none; }
  .lg-outer .lg-toggle-thumb:after {
    content: "\e1ff"; }
  .lg-outer .lg-toggle-thumb:hover {
    color: #FFF; }

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px; }

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative; }
  .lg-outer .lg-video .lg-object {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }
  .lg-outer .lg-video .lg-video-play {
    width: 84px;
    height: 59px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -42px;
    margin-top: -30px;
    z-index: 1080;
    cursor: pointer; }

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 -58px transparent; }

.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../images/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8; }

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1; }

.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 -60px transparent; }

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0; }

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden; }

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none; }

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible; }

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s; }
  .lg-progress-bar .lg-progress {
    background-color: #a90707;
    height: 5px;
    width: 0; }
  .lg-progress-bar.lg-start .lg-progress {
    width: 100%; }
  .lg-show-autoplay .lg-progress-bar {
    opacity: 1; }

.lg-autoplay-button:after {
  content: "\e01d"; }
  .lg-show-autoplay .lg-autoplay-button:after {
    content: "\e01a"; }

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s; }

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

#lg-zoom-in:after {
  content: "\e311"; }

#lg-actual-size {
  font-size: 20px; }
  #lg-actual-size:after {
    content: "\e033"; }

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none; }
  #lg-zoom-out:after {
    content: "\e312"; }
  .lg-zoomed #lg-zoom-out {
    opacity: 1;
    pointer-events: auto; }

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px; }
  .lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
    overflow: visible; }

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px; }
  .lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
    box-shadow: 0 0 0 2px white inset; }

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0); }
  .lg-outer .lg-pager-thumb-cont img {
    width: 100%;
    height: 100%; }

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px; }
  .lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
    box-shadow: 0 0 0 8px white inset; }

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0; }

.lg-fullscreen:after {
  content: "\e20c"; }
  .lg-fullscreen-on .lg-fullscreen:after {
    content: "\e20d"; }

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s; }

.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible; }

.lg-outer.lg-dropdown-active #lg-share {
  color: #FFF; }

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s; }
  .lg-outer .lg-dropdown:after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    border: 8px solid transparent;
    border-bottom-color: #FFF;
    right: 16px;
    top: -16px; }
  .lg-outer .lg-dropdown > li:last-child {
    margin-bottom: 0px; }
  .lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon {
    color: #333; }
  .lg-outer .lg-dropdown a {
    color: #333;
    display: block;
    white-space: pre;
    padding: 4px 12px;
    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 12px; }
    .lg-outer .lg-dropdown a:hover {
      background-color: rgba(0, 0, 0, 0.07); }
  .lg-outer .lg-dropdown .lg-dropdown-text {
    display: inline-block;
    line-height: 1;
    margin-top: -3px;
    vertical-align: middle; }
  .lg-outer .lg-dropdown .lg-icon {
    color: #333;
    display: inline-block;
    float: none;
    font-size: 20px;
    height: auto;
    line-height: 1;
    margin-right: 8px;
    padding: 0;
    vertical-align: middle;
    width: auto; }

.lg-outer #lg-share {
  position: relative; }
  .lg-outer #lg-share:after {
    content: "\e80d"; }

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998; }
  .lg-outer #lg-share-facebook .lg-icon:after {
    content: "\e904"; }

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced; }
  .lg-outer #lg-share-twitter .lg-icon:after {
    content: "\e907"; }

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39; }
  .lg-outer #lg-share-googleplus .lg-icon:after {
    content: "\e905"; }

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027; }
  .lg-outer #lg-share-pinterest .lg-icon:after {
    content: "\e906"; }

.lg-outer .lg-img-rotate {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  transition: transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s; }

.lg-rotate-left:after {
  content: "\e900"; }

.lg-rotate-right:after {
  content: "\e901"; }

.lg-icon.lg-flip-hor, .lg-icon.lg-flip-ver {
  font-size: 26px; }

.lg-flip-hor:after {
  content: "\e902"; }

.lg-flip-ver:after {
  content: "\e903"; }

.login-block {
  max-width: 500px; }

.login-info {
  margin: 0 0 20px; }
  .login-info__text {
    font-size: 15px;
    line-height: 24px;
    color: #282828; }

.login-form {
  position: relative; }

.login-forgot {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #D0D0D0;
  margin: 15px 0 0;
  padding: 15px 0 0; }
  .login-forgot > a, .login-forgot > span {
    font-size: 15px;
    line-height: 18px;
    color: #999999; }
    .login-forgot > a > a, .login-forgot > span > a {
      color: #E5A701;
      text-decoration: underline; }
  @media (max-width: 419px) {
    .login-forgot {
      flex-direction: column-reverse;
      align-items: center; }
      .login-forgot > a {
        margin: 10px 0 0; } }
