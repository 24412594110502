.theme-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  svg {
    width: 36px;
    height: 28px;
    fill: #14A356;
    margin: 0 0 25px;
  }

  span {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #333333;
    @include _992 {
      font-size: 24px;
      line-height: 28px;
    }
  }
}


// Basic Modal Styles
.base-modal {
  display: none;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  position: relative;

  &.show {
    display: flex;
  }
  &__filter {
    width: 100vw;
  }
  &__close {
    position: absolute;
    width: fluidFull(40,74);
    height: fluidFull(40,74);
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    & > svg {
      @include _1200 {
        height: 10px;
        width: 10px;
      }
    }
    &:hover rect {
      fill: $black;
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
     & > div {
       width: calc(50% - 10px);

     }
    @include _1200 {
      flex-direction: column;
      justify-content: unset;
      & > div {
        width: 100%;
        &:last-child {
          margin-top: 10px;
        }
      }
    }
  }
  &-request {
    width: fluidFull(600,1026);
    &__bottom {
      margin-top: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      & .base-modal-privacy {
        border: none;
        margin: 0;
        max-width: 368px;
        padding: 0;
        &__text {
          text-align: left;
        }
      }
      @include _576 {
        margin-top: 10px;
        flex-direction: column-reverse;
        & .base-modal-privacy {
          border: none;
          margin: 15px 0 0;
          max-width: 195px;
          padding: 0;
          &__text {
            text-align: center;
          }
        }
      }
    }
    &__btn {
      margin: 0 0 0 21px !important;
      @include _576 {
        margin: 0 !important;
      }
    }
    &__textarea {
      margin-top: 20px;
      @include _1200 {
        margin-top: 10px;
      }
      font-size: 18px;
      line-height: 22px;
      height: 305px;
      padding: 14px 30px;
      border: 1px solid #8C8E99;
      border-radius: 10px;
      transform: none;
    }

  }
  &-order {
    &__title {
      font-weight: 500;
    }
  }
  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 100px;
    height: 100%;
    background: rgba(0, 0, 0, 0.45);
    display: none;
    justify-content: center;
    z-index: 9999;
    overflow-y: scroll;
  }
  &-success {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    &__title {
      @include gradient-text($main-gradient);
      margin-top: 40px;
    }
  }
  width: fluidFull(400,550);
  @include _576 {
    width: 100% !important;
  }
  padding: 60px;
  @include _1440 {
    padding: 50px 30px 40px;
    @include _768 {
      padding: 45px 20px 35px;
    }
  }
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

  &__title {
    font-weight: 600;
    font-size: fluidFull(16,32);
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #1F2026;
  }

  &__description {
    margin-top: 20px;
    @include _1200 {
      margin-top: 15px;
    }
    font-weight: 400;
    font-size: fluidFull(12,20);
    line-height: fluidFull(16,29);
    text-align: center;
    color: #5D5E62;
    @include _1200 {
      color: #404040;
    }
  }

  &__group {
    margin-top: 40px;
    width: 100%;

    &--33 {
      margin-top: 33px;

    }
   @include _576 {
      margin-top: 30px;
    }
  }

  &-privacy {
    margin-top: 30px;
    @include _1200 {
      margin-top: 15px;
    }
    border-top: 1px solid #D9D9D9;
    padding-top: 12px;
    display: flex;
    justify-content: center;

    &__text {
      font-weight: 500;
      font-size: fluidFull(10,14);
      line-height: 17px;
      text-align: center;
      color: #1F2026;

      & > a {
        @include gradient-text($main-gradient);
        text-decoration: underline;
      }
    }
  }

  &__btn {
    margin-top: 20px;
    appearance: none;
    outline: none;
    border: none;
  }
}

.js-modal-opened {
  overflow: hidden;

  & .base-modal__bg {
    display: flex;
  }
}