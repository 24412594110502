.container{
	max-width: 1650px;
	margin: 0 auto;
	padding: 0 15px;
	@include _1200 {
		padding: 0 20px;
	}
}
._ {
	&576 {
		&_d {
			display: none;
			@include _576 {
				display: block;
			}
		}
		&_h {
			display: block;
			@include _576 {
				display: none;
			}
		}
	}
}

.d-flex {
	display: flex;
}
.w100 {
	width: 100%;
}
.ai-center {
	align-items: center;
}
.jc-sb {
	justify-content: space-between;
}

//#page-container{
//	padding: 0 0 100px;
//	@include _992{
//		padding: 0 0 60px;
//	}
//}

em{
	font-style: normal;
	color: $main-clr-hover;
}

.loading{
	// opacity: .4;
	filter: blur(3px);
	pointer-events: none;
	user-select: none;
}

.d-flex-row{
	display: flex;
	flex-direction: row;
}

.base {
    &__title {
        @include font(32,46);
        font-weight: 500;
        color: $black;
        text-align: center;
        text-transform: uppercase;
        &--inverse {
            color: #fff;
        }
        @include _1650 {
            @include font(28, 40);
            @include _1200 {
                @include font(24, 30);
                @include _992 {
                    @include font(20,26);
                    @include _576 {
                        @include font(16,16);
                    }
                }
            }
        }
    }
}
.breadcrumbs{
	padding: 40px 0;
	@include _992{
		padding: 20px 0;
	}
	&-item{
		font-size: 18px;
		line-height: 20px;
		font-weight: 300;
		@include _992{
			font-size: 12px;
			line-height: 16px;
		}
		position: relative;
		margin-right: 20px;
		color: $black;
		&:last-child {
			@include gradient-text($main-gradient);
		}
		&:not(:last-child) {
			&:hover {
				& span > span {
					text-decoration: underline;
				}
				color: $black ;
				&:after {
					@include gradient-text($black);
				}
			}
			&:after {
				top: 0;
				left: 100%;
				position: absolute;
				width: 20px;
				height: 100%;
				display: flex;
				align-items: center;
				content: " / ";
				color: $black ;
				justify-content: center;
			}
		}
	}
	&-back{
		display: block;
		margin: 15px 0 0;
		padding: 10px 0;
		&:hover{
			svg{
				fill: $main-clr-hover;
			}
			span{
				color: $main-clr-hover;
			}
		}
		svg{
			width: 16px;
			height: 10px;
			fill: $text-clr;
			margin-right: 5px;
			transition: fill $transition-time-base;
		}
		span{
			font-weight: 500;
			font-size: 15px;
			line-height: 18px;
			color: $text-clr;
			transition: color $transition-time-base;
		}
	}
}



.swiper{
	transition: opacity $transition-time-base;
	opacity: 1;

	&-container-free-mode &-wrapper {
		transition-timing-function : linear;
	}
	&-init{
		opacity: 0;
	}
	&-nav{
		cursor: pointer;
		&--next{
			svg{
				transform: rotate(180deg);
			}
		}
		svg{
			width: 36px;
			height: 25px;
			fill: #999999;
			transition: fill $transition-time-base;
		}
		&:hover{
			svg{
				fill: $alt-clr;
			}
		}
	}
	&-pagination{
		z-index: 1;
	}
}
.swiper-button-disabled{
	opacity: .6;
	pointer-events: none;
}

.no-photo{
	background-image: url('../images/placeholder.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}


