@import "lg-variables";
@import "lg-mixins";
.lg-outer {

    #lg-dropdown-overlay {
        background-color: rgba(0, 0, 0, 0.25);
        bottom: 0;
        cursor: default;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: $zindex-toolbar - 1;
        opacity: 0;
        visibility: hidden;
        @include transition(visibility 0s linear 0.18s, opacity 0.18s linear 0s);
    }
    &.lg-dropdown-active{
        .lg-dropdown, #lg-dropdown-overlay {
            @include transition-delay(0s);
            @include transform(translate3d(0, 0px, 0));
            opacity: 1;
            visibility: visible;
        }
        #lg-share {
            color: #FFF;
        }
    }
    .lg-dropdown {
        background-color: #fff;
        border-radius: 2px;
        font-size: 14px;
        list-style-type: none;
        margin: 0;
        padding: 10px 0;
        position: absolute;
        right: 0;
        text-align: left;
        top: 50px;
        opacity: 0;
        visibility: hidden;
        @include transform(translate3d(0, 5px, 0));
        @include transitionCustom(transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s);

        &:after {
            content: "";
            display: block;
            height: 0;
            width: 0;
            position: absolute;
            border: 8px solid transparent;
            border-bottom-color: #FFF;
            right: 16px;
            top: -16px
        }
        > li {
            &:last-child {
                margin-bottom: 0px;
            }
            &:hover {
                a, .lg-icon {
                    color: #333;
                }
            }
        }
        a {
            color: #333;
            display: block;
            white-space: pre;
            padding: 4px 12px;
            font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
            font-size: 12px;
            &:hover {
                background-color: rgba(0, 0, 0, 0.07);
            }

        }
        .lg-dropdown-text {
            display: inline-block;
            line-height: 1;
            margin-top: -3px;
            vertical-align: middle;
        }
        .lg-icon {
            color: #333;
            display: inline-block;
            float: none;
            font-size: 20px;
            height: auto;
            line-height: 1;
            margin-right: 8px;
            padding: 0;
            vertical-align: middle;
            width: auto;
        }
    }
    #lg-share {
        position: relative;
        &:after {
            content: "\e80d";
        }
    }
    #lg-share-facebook {
        .lg-icon{
            color: #3b5998;
            &:after {
                content: "\e904";
            }
        }   
    }
    #lg-share-twitter {
        .lg-icon{
            color: #00aced;
            &:after {
                content: "\e907";
            }
        }  
    }
    #lg-share-googleplus {
        .lg-icon{
            color: #dd4b39;
            &:after {
                content: "\e905";
            }
        }  
    }
    #lg-share-pinterest {
        .lg-icon{
            color: #cb2027;
            &:after {
                content: "\e906";
            }
        }  
    }
}