@import 'base/app';

.login-container{
    
}

.login-block{
    max-width: 500px;
}

.login-info{
    margin: 0 0 20px;
    &__text{
        font-size: 15px;
        line-height: 24px;
        color: $text-clr;
    }
}
.login-form{
    position: relative;
}

.login-forgot{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #D0D0D0;
    margin: 15px 0 0;
    padding: 15px 0 0;
    > a, >span{
        font-size: 15px;
        line-height: 18px;
        color: #999999;
        > a{
            color: $main-clr-hover;
            text-decoration: underline;
        }
    }

    @include _420{
        flex-direction: column-reverse;
        align-items: center;
        > a{
            margin: 10px 0 0;
        }
    }
}