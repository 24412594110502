@mixin font1826 {
  @include font(18,26);
  @include _1650 {
    @include font(16,24)
  }
  @include _1440 {
    @include font(14,22);
  }
}
@mixin font1822 {
  @include font(18,22);
   @include _1440 {
    @include font(16,20);
  }
}
@mixin font2029 {
  @include font(20,29);
  @include _1650 {
    @include font(18,26);
  }
  @include _1440 {
    @include font(16,22);
  }
}
@mixin font1623{
  @include font(16,23);
  @include _1440 {
    @include font(14,20);
  }
}

.header {
  background: $black;
  &-top {
    height: 56px;
    background: $black1;
    display: flex;
    align-items: center;
    &__left {
      display: flex;
      align-items: center;
      height: 100%;
    }
    &__contacts, &__social {
      display: flex;
      align-items: center;
    }
    &__social {
      display: flex;
      margin-left: 70px;
      @include _1440 {
        margin-left: 40px;
      }
    }
    &__nav {
      display: flex;
      align-items: center;
    }

    &-nav {
      display: block;
      &:not(:last-child) {
        margin-right: 35px;
      }
      @include font1826;
      font-weight: 300;
      color: $black1-text;
      &:hover {
        text-decoration: underline;
        color: #fff;
      }
    }
    &-contact {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-right: 70px;
      }
      &__icon {
        height: 25px;
        width: 25px;
        margin-right: 20px;
      }
      &__text {
        @include font1826;
        font-weight: 300;
        color: $black1-text;
      }
    }
    &-social {
      display: flex;
      align-items: center;
      justify-content: center;
      &:not(:last-child) {
        margin-right: 35px;
      }
      & .vk {
        width: 32px;
        height: 20px;
      }
      & .inst {
        width: 25px;
        height: 25px;
      }
      @include _1440 {
        margin-right: 15px;
        & .vk {
          transform: scale(0.8);
        }
        & .inst {
          transform: scale(0.8);
        }
        @include _768 {
          & .vk, & .inst {
            transform: none;
          }
        }
      }
      &:hover {
        & path {
          fill: #fff;
        }
      }
    }
  }
  &-main {
    display: flex;
    align-items: center;
    height: 100px;
    &-logo {
      position: relative;
      height: 80px;
      margin: 20px 0;
      display: block;
      width: 143.57px;
      &__img {
        width: 100%;
        height: 100%;
      }
      &__text {
        font-size: 15px;
        line-height: 22px;
        font-weight: 400;
        left: -30%;
        width: 150%;
        position: absolute;
        top: 92%;
        color: #fff;
        text-align: center;
        @include _1440 {
          font-size: 12px;
          @include _1200 {
            display: none;
          }
        }
      }
      @include _1650 {
        margin: 15px 0;
      }
      @include _1440 {
        height: 60px;
        width: 107.6775px;
      }
    }
    &-search {
      $skew: 14deg;
      margin-left: 90px;
      flex: 1;
      height: 50px;
      background: linear-gradient(90deg, #40BAEB 0%, #4066EB 66.88%, #40BAEB 100%);
      border-radius: 5px;
      transform: skewX(-$skew);
      position: relative;
      @include _1440 {
        margin-left: 60px;
        height: 40px;
      }
      @include _1200 {

        margin-left: 0;
        flex: none;
        width: 100%;
      }
      &__inner {
        position: absolute;
        left: 1px;
        top: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background: $black1;
        border-radius: 5px;
      }
      &__input {
        transform: skewX($skew);
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        @include font1822;
        font-weight: 400;
        color: #fff;
        padding: 0 100px 0 30px;
        &::placeholder {
          @include font1822;
          font-weight: 400;
          color: $black1-text;
        }
      }
      &-btn {
        z-index: 1;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 72px;
        border-radius: 5px;
        height: 50px;
        background: $main-gradient;
        @include _1440 {
          height: 40px;
          width: 60px;
        }
        &__icon {
          transform: skewX($skew);
          width: 25px;
          height: 25px;
          fill: #fff;
          @include _1440 {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    &-cart {
      position: relative;
      cursor: pointer;
      padding-top: 14px;

      margin-left: 40px;
      $h-cart: &;
      &__container {
        appearance: none !important;
        overflow: auto !important;
        display: flex;
         flex-direction: column;
        align-items: center;
      }
      &__inner {
        position: relative;
      }
      &__icon {
        width: 21px;
        height: 23px;
        fill: transparent;
      }
      &__count {
        position: absolute;
        bottom: -11px;
        right: -13px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $gray;
        border-radius: 9.5px;
        padding: 1px 9px;
        @include color-transition($transition-time-base);
        @include font(12,17);
        font-weight: 300;
        color: $black1-text1;
      }
      &__text {
        margin-top: 7px;
        @include font(14,20);
        font-weight: 300;
        color: $black1-text1;
      }
      &-dropdown {
        &__inner {
          cursor: default;
          margin-top: 23px;
          background: #fff;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
          min-width: 363px;
          max-width: 363px;
        }
        background: transparent;
        display: none;
        position: absolute;
        z-index: 4;
        right: -16px;
        top: 100%;
        &__header {
          position: relative;
          padding: 10px 20px;
          width: 100%;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
          background: $gray1;
          display: flex;
          align-items: center;
          &:before {
            content: "";
            position: absolute;
            right: 30px;
            top: -10px;
            width: 21px;
            height: 21px;
            background: $gray1;
            transform: rotate(45deg) skew(-8deg, -8deg);
          }
        }
        &__footer {
          padding: 16px 20px;
          display: flex;
          justify-content: space-between;
          align-items: end;
        }
        &__content {
          height: 293px;
          max-height: 293px;
          margin: 20px;
          position: relative;
          & .ss-scroll {
            right: -15px !important;
            width: 5px;
            background: $black1;
            position: absolute;
            border-radius: 2px !important;
            opacity: 1 !important;
          }
        }
        &__title, &__count {
          @include font1826;
          font-weight: 500;
          color: $gray1-text;
        }
        &__count {
          color: $cart-count;
        }
        &__total_text {
          @include font2029;
          font-weight: 300;
          color: $black;
        }
        &__total {
          @include font(24,35);
          font-weight: 500;
          color: $black;
        }
      }
      &-item {
        display: flex;
        align-items: center;
        &__img {
          width: 42px;
          height: 80px;
          object-fit: contain;
        }
        &-content {
          margin-left: 20px;
          display: flex;
          align-items: center;
          flex: 1;
          &__left {
            flex: 1;
          }
        }
        &__title {
          display: block;
          @include font1623;
          font-weight: 400;
          color: $black3;
          &:hover {
            color: $black3;
          }
        }
        &__count {
          @include font1623;
          font-weight: 400;
          color: $gray2;
        }
        &__total {
          margin-left: 11px;
          width: fit-content;
          min-width: fit-content;
          padding-right: 2px;
          @include font1826;
          font-weight: 500;
          color: $black;
        }
      }
      &:hover, &:not(.hide-dropdown) {
        #{$h-cart} {
          &__count {
            background: $main-gradient;
          }
        }
      }
      &:not(.hide-dropdown):hover &-dropdown {
          display: block;
      }

    }
    &__right {
      width: fit-content;
      margin-left: 84px;
      display: flex;
      align-items: center;
      @include _1440 {
        margin-left: 60px;
      }
    }
    &__recall {
      margin-left: 30px;
      @include _1200 {
        margin-left: 0;
        width: 100%;
        margin-top: 22.5px;
      }
      @include _576 {
        max-width: unset;
      }
    }
    &__phone {
      @include font2029;
      font-weight: 500;
      color: $black1-text1;
      @include _1200 {
        display: block;
        margin-top: 17.5px;
        margin-bottom: 2.5px;
      }
      &:hover {
        @include gradient-text($main-gradient);
      }
    }
  }
  &-catalog {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__categories {
      display: flex;
      align-items: center;
    }
    &-category, &__all {
      $header-catalog-category: &;
      padding: 25px 17.5px;
      display: block;
      @include _1440 {
        padding: 20px 12.5px;
      }
      @include font2029;
      font-weight: 400;
      user-select: none;
      text-transform: uppercase;
      @include color-transition($transition-time-base);
      transition: background-color $transition-time-base;
      color: #fff;
      &:first-child {
        padding: 25px 17.5px 25px 0;
      }
      &:last-child {
        padding: 25px 0 25px 17.5px;
      }
      position: relative;
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        height: 4px;
        width: 0%;
        background: $main-gradient;
        transition: width .2s, left .2s;
      }
      &:hover {
          &:after {
            width: 100%;
            left: 0%;
          }
        @include gradient-text($main-gradient);
      }
    }
    &-category {
      position: relative;
      &__dropdown {
        position: absolute;
        appearance: none;
        top: 100%;
        left: 0;
        display: none;
        min-width: 100%;
        background: $white1;
        // padding: 20px;
        z-index: 2;
      }
      $header-catalog-category: &;

      &:hover > #{$header-catalog-category}__dropdown {
        display: block;
        width: max-content;
      }

    }
    &-subcategory {
      @include font1826;
      display: flex;
      font-weight: 400;
      text-transform: none;
      padding: 10px 20px;
      min-width: max-content;
      width: 100%;
      z-index: 1;
      @include gradient-text($black);

      &:first-child {
        // padding: 0 0 5px 0;
        padding: 20px 20px 10px;
      }
      &:last-child {
        // padding: 5px 0 0 0;
        padding: 10px 20px 20px;
      }
      &:only-child {
        padding: 20px;
      }
      &:hover {
        @include gradient-text($main-gradient);
      }
    }
    &__all {
      padding: 25px 17.5px !important;
      margin-right: -17.5px;
    }
  }
}

