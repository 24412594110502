.form-group {
    position: relative;
    &20 > .base-input{
        display: flex;
        margin: 20px 0;
        &:first-child {
            margin: 0 0 20px;
        }
        &:last-child {
            margin: 20px 0 0;
        }
        @include _992 {
            margin: 10px 0 !important;
            &:first-child {
                margin: 0 0 10px !important;
            }
            &:last-child {
                margin: 10px 0 0 !important;
            }
        }
    }
    &15 > .base-input {
        margin: 15px 0;
        &:first-child {
            margin: 0 0 15px;
        }
        &:last-child {
            margin: 15px 0 0;
        }
        @include _992 {
            margin: 10px 0 !important;
            &:first-child {
                margin: 0 0 10px !important;
            }
            &:last-child {
                margin: 10px 0 0 !important;
            }
        }
    }
    &__error {
        display: none;
        position: absolute;
        top: calc(100% + 5px);
        right: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #FB474E;
        @include _1200 {
            font-weight: 400;
            font-size: 12px;
            line-height: 142%;
            text-align: center;
            color: #FB474E;
            top: unset;
            bottom: calc(100% + 5px);
        }
    }
    &.error .js-base-input {
        border: 1px solid #FB474E !important;
    }
    &.error &__error {
        display: block;
    }
}

.base-input {
    $skew: 15deg;
    border: 1px solid $gray5;
    background: white;
    border-radius: 5px;
    overflow: hidden;
    transform: skewX(-$skew);
    width: 100%;
    height: 50px;
    &--borderC0 {
        border: 1px solid #C0C0C0;
    }
    @include _1440 {
        height: 45px;
        @include _992 {
            height: 40px;
        }
    }

    &:has(&__input:focus) {
        border: 1px solid $black;

    }
    &:has(&__input:focus) &__input::placeholder {
        opacity: 0;
    }
    &--center &__input {
        text-align: center;
    }
    &__input {
        color: $black;
        width: 100%;
        height: 100%;
        appearance: none;
        border: none;
        padding: 0 30px;
        transform: skewX($skew);
        &, &::placeholder {
            font-family: 'Montserrat', sans-serif;
            @include font(18,22);
            @include _992 {
                @include font(16,20);
            }
            font-weight: 400;
        }
        &::placeholder {
            color: $gray5;
        }
        @include _992 {
            text-align: center;
        }
    }
}