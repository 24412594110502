.footer {
    background: $black;
    border-top: 2px solid $gray4;
    &-main {
        padding: 65px 0 90px;
        @include _1650 {
            padding: 65px 15px 90px;
        }
        @include _768 {
            padding: 35px 15px 60px;
        }
        @include _576 {
            padding: 25px 20px;
        }
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        &__logo {
            margin: -11px 0 15px;
            @include _768 {
                margin: 0;
                @include _576 {
                    height: 60px;
                    margin-bottom: 20px;
                }
            }

        }
        &-info {
            @include _768 {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                justify-content: space-around;
                @include _576 {
                    flex-direction: column;
                    justify-content: unset;
                    &__container {
                        width: 100%;
                    }
                }
            }


        }
        &__info {
            @include font(18,26);
            padding: 5px 0;
            font-weight: 400;
            color: $gray5;
            &.link {
                color: $gray3;
                display: block;
                line-height: 22px;
                text-align: right;
                margin-top: 15px;
                @include _576 {
                    margin-top: 0;
                }
            }
            &:hover {
                color: #fff;
            }
            @include _576 {
                @include font(14,20);
            }
        }
        &__left {
            flex: 1;
            display: flex;
            justify-content: space-between;
            @include _1200 {
                justify-content: space-around;
            }
            @include _768 {
                flex-direction: column;
                align-items: center;
            }

        }
    }
    &__phone {
        display: block;
        @include font(28,40);
        font-weight: 500;
        color: #fff;
        text-align: right;
        @include _1440 {
            @include font(24, 30);
            @include _1200 {
                @include font(22, 26);
            }
        }
        &:hover {
            @include gradient-text($main-gradient);
        }
    }
    &-bottom {
        border-top: 2px solid $gray4;
        &__inner {
            display: flex;
            justify-content: space-between;
            flex-flow: row wrap;
            padding: 30px 0 25px;
            @include _1650 {
                padding: 20px 20px 15px;
                @include _768 {
                    padding: 15px 20px;
                }
            }
        }
        &__rights {
            font-weight: 300;
            font-size: fluidFull(12,18);
            line-height: 140%;
            color: #8C8E98;
            @include _992 {
                width: 50%;
                @include _576 {
                    width: 100%;
                }
            }

        }
        &__privacy {
            font-weight: 500;
            font-size: fluidFull(14,18);
            line-height: 140%;
            color: #8C8E98;
            padding: 0 20px;
            @include _992 {
                padding: 0;
                width: 50%;
                text-align: right;
                @include _576 {
                    margin-top: 10px;
                    text-align: left;
                    display: block;
                    width: 100%;
                }
            }
        }
        &__placestart {
            margin-left: -3px;
            @include _992 {
                margin-top: 10px;
            }
            width: fluidFull(142.45, 216);
            height: fluidFull(17,26);
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    &__links {
        display: flex;
        margin-left: 30px;
        margin-top: -6px;
        @include _768 {
            width: 100%;
            justify-content: space-around;
            margin-left: 0;
            margin-top: 30px;
            @include _576 {
                flex-direction: column;
                justify-content: unset;
            }
        }
    }
    &__navigation {
        &:not(:first-child) {
            margin-left: 90px;
            @include _1440 {
                margin-left: 30px;
            }
            @include _768 {
                margin-left: 0;
            }
            @include _576 {
                margin-top: 20px;
            }
        }
    }
    &-nav {
        @include font(18,26);
        font-weight: 400;
        padding: 5px 0;
        display: block;
        color: $gray5;
        &:hover {
            color: #fff;
        }
    }
    &-contacts {
        margin-left: 382px;
        @include _1650 {
            margin-left: 170px;
        }
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @include _1440 {
            margin-left: 90px;
        }
        @include _1200 {
            margin-left: 0;
            width: 100%;
            justify-content: space-around;
            align-items: center;
            flex-direction: row;

        }
        @include _576 {
            flex-direction: column;
            align-items: flex-start;
        }
        &__bottom {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            @include _1200 {
                flex-flow: column-reverse;
                align-items: center;
            }
            @include _576 {
                align-items: flex-start;
            }
        }
        &__left {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            @include _1200 {
                align-items: center;
            }
            @include _576 {
                align-items: flex-start;
                margin-top: 30px;
            }
        }
        &__social {
            margin-left: 0;
            @include _1200 {
                margin-top: 20px;
            }
        }
    }
    &__order {
        margin-top: 38px;
        @include _1200 {
            margin-top: 0;
        }
    }
}