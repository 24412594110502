.btn {
  $skew: 15deg;
  transform: skewX(-$skew);
  max-width: 270px;
  min-width: 270px;
  user-select: none;
  height: 50px;
  @include _1440 {
    height: 45px;
  }
  @include _992 {
    height: 40px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: $black1-text1;
  background: $black;
  border-radius: 5px;
  border: none;
  position: relative;
  @include color-transition($transition-time-base);
  & span {
    transform: skewX($skew);
    @include font(18,22);
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: $black1-text1;

    @include _1440 {
      @include font(16,20);
    }
  }
  $btn: &;
  @include _576 {
    width: calc(100% - 12px);
    margin-left: 6px;
  }
  &:hover {
    #{$btn}--inner {
      background: $main-gradient;
    }
  }
  &--white {
    background: inherit;
    border: 1px solid #fff;
    &:hover {
      background: #fff;
      & span {
        color: $black;
      }
    }
  }
  &--gradient {
    background: $main-gradient;
    &:hover {
      background: $main-gradient-hover;
    }
  }
  &--inverse {
    background: #fff;
    border: 1px solid #fff;
    & span {
      color: $black;
    }
    &:hover {
      background: transparent;
      & span {
        color: #fff;
      }
    }
  }
  &--mw100 {
    @include _576 {
      width: 100%;
      max-width: unset;
    }
  }
  &--inner {
    position: absolute;
    top: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background: $black1;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include color-transition($transition-time-base);
  }
}
