@function calcFluid($f-min, $f-max, $v-min: 320, $v-max: 1920, $base: 0) {
    $k: ($f-max - $f-min)/($v-max - $v-min);
    $b: $f-min - $k * $v-min;
 
    $b: $b * 1px;

    @if $base == 0{
        @return calc(#{$k} * 100vw + #{$b} );
    }
    @else{
        @return calc( #{$base} - (#{$k} * 100vw + #{$b}) );
    }
}

@function fluidFull($value-min, $value-max) {
  @return calcFluid($value-min, $value-max, 320, 1920);
}


@function calcFluidP($f-min, $f-max, $v-min: 320, $v-max: 1920, $percent: 100) {
	$k: ($f-max - $f-min)/($v-max - $v-min);
	$b: $f-min - $k * $v-min;
  
	$b: $b * 1px;

	$p: $percent * 1%;
  
	@return calc( #{$p} + (#{$k} * 100vw + #{$b}) );
}

@function scaleFluid($val, $multiplier, $min-window-width, $max-window-width) {
  $result: $val * $multiplier;
  $val-to-ww-ratio: ($val - $result) / ($max-window-width - $min-window-width);
  $min: $result - $val-to-ww-ratio * $min-window-width;
  @return calc(#{$val-to-ww-ratio} * 100vw + #{$min}px);

}

@function scaleFluidFull($value, $multiplier) {
  @return scaleFluid($value, $multiplier, 320, 1920);
}