@import "lg-variables";
@import "lg-mixins";
.lg-outer {
    .lg-img-rotate {
        position: absolute;
        padding: 0 5px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        @include transitionCustom(transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s);
    }
}
.lg-rotate-left {
    &:after {
        content: "\e900";
    }
}
.lg-rotate-right {
    &:after {
        content: "\e901";
    }
}
.lg-icon {
    &.lg-flip-hor, &.lg-flip-ver {
        font-size: 26px;
    }
}
.lg-flip-hor {
    &:after {
        content: "\e902";
    } 
}
.lg-flip-ver {
    &:after {
        content: "\e903";
    } 
}