* {
	user-select: none;
}

input {
	user-select: text !important;
}


body{
	min-height: 100vh;
	margin: 0;
	color: $text-clr;
	font-family: 'Jost', sans-serif;
	display: flex;
	flex-direction: column;
	position: relative;
	&.opened{
		overflow: hidden;
	}
}
main{
	position: relative;
	//overflow: hidden;
	//@include _1200 {
	//	overflow: hidden;
	//}
	flex: 1 0 auto;
}
.text {
	font-family: 'Montserrat', sans-serif;
}
p,ol,ul,h1,h2,h3,button,form{
	margin: 0;
	padding: 0;
}
button, input, textarea{
	font-family: inherit;
	&:focus{
		outline: none;
	}
}
input{
	min-width: 0;
	font-size: inherit;
	appearance: none;
}
ul, li{
	list-style-type: none;
}

input::-webkit-input-placeholder{
	font-size: 15px;
	line-height: 18px;
	color: #999999;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type=number] {
	-webkit-appearance: none;
	-moz-appearance: textfield;
}
@include _1650 {
	body {
		zoom: .8;
		@include _992 {
			zoom: 1;
		}
	}
}
a{
	color: $black;
	text-decoration: none;
	transition: color $transition-time-base;
}
svg{
	vertical-align: middle;
	transition: fill $transition-time-base;
}
picture{
	display: block;
}
img{
	display: block;
}
source{
	display: none;
}
*, ::before, ::after{
	box-sizing: border-box;
}
:focus{
	outline: none;
}
