

@keyframes burger1 {
    from {
        top: 0;
    }
    50% {
        top: 7px;
    }
    55% {
        top: 7px;
    }
    to {
        top: 7px;
        transform: rotate(135deg);
    }
}
@keyframes burger2 {
    from {
    }
    50% {
    }
    55% {
        opacity: 0;
    }
    to {
        opacity: 0;
    }
}
@keyframes burger3 {
    from {
    }
    50% {
        bottom: 7px;
    }
    55% {
        bottom: 7px;
    }
    to {
        bottom: 7px;
        transform: rotate(-135deg);
    }
}

.header-mobile {
    width: 100%;
    height: 70px;
    display: flex;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
    @include _768 {
        height: 50px;
    }
    position: relative;
    &-logo {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        pointer-events: none;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &__inner {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__icon {
            display: block;
            pointer-events: auto;
            & > svg {
                width: 120px;
                height: 50px;
                @include _768 {
                    width: 60px;
                    height: 25px;
                }
            }
        }
        &__text {
            font-size: 16px;
            @include _768 {
                font-size: 8px;
            }
            color: #fff;
        }

    }
    &-burger {
        display: flex;
        position: relative;
        z-index: 2;
        align-items: center;
        &-icon {
            width: 22px;
            position: relative;
            height: 16px;
            &__row {
                width: 100%;
                height: 2px;
                background: #fff;
                border-radius: 10.5px;
                position: absolute;
                &:first-child {
                    top: 0;
                }
                &:nth-child(2) {
                    top: 7px;
                }
                &:last-child {
                    bottom: 0;
                }
                transition: top .3s, bottom .3s, rotate .3s;
            }

        }
        &.active &-icon {
            &__row {
                &:first-child {
                    animation: burger1 .6s ease;
                }
                &:nth-child(2) {
                    animation: burger2 .6s ease;
                }
                &:last-child {
                    animation: burger3 .6s ease;
                }
                animation-fill-mode: forwards !important;
            }
        }
        //& > svg:first-child {
        //    width: 22px;
        //    height: 16px;
        //}
        //& > svg:last-child {
        //    display: none;
        //    width: 16px;
        //    height: 16px;
        //}
        //&.active {
        //    & > svg:first-child {
        //        display: none;
        //    }
        //    & > svg:last-child {
        //        display: block;
        //    }
        //}
    }
    &__buttons {
        position: relative;
        z-index: 2;
    }
    &-btn {
        width: 18px;
        height: 18px;
        &:not(:last-child) {
            margin-right: 26px;
        }
        & .has-items {
            display: none;
            position: absolute;
            top: 0;
            right: -4px;
            width: 9px;
            border-radius: 50%;
            height: 9px;
            background: $main-gradient;
        }
        &.cart {
            width: 17.35px;
            height: 19px;
            &.active {
                & > .has-items {
                    display: block;
                }
            }
        }
    }
    &-menu {
        position: fixed;
        z-index: 55;
        overflow-y: scroll;
        background: $black;
        padding: 20px;
        left: 0;
        display: none;
        &.active {
            display: block;
        }
        width: 100%;
        top: 70px;
        height: calc(100vh - 70px);
        @include _768 {
            top: 50px;
            height: calc(100vh - 50px);
        }

    }
    &__categories {
        width: 100%;
        margin-top: 17.5px;
    }
    &-category {
        &__row {
            padding: 12.5px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__title {
            @include font(18,16);
            font-weight: 500;
            color: white;
            display: block;
            padding-right: 30px;
            text-transform: uppercase;
            @include _768 {
                @include font(14,14);
            }
        }
        &__icon {
            transform: rotate(-45deg);
            width: 15px;
            height: 15px;
            transition: transform $transition-time-base;
            @include _768 {
                width: 11px;
                height: 11px;
            }
            &-wrapper {
                display: flex;
                justify-content: flex-end;
                flex: 1;
            }
        }
        &__items {
            padding: 0 15px;
            display: none;
        }
        &.active {
            & .header-mobile-category__title {
                color: $border1 !important;
            }
        }
        &.active &__row {
            padding: 12.5px 0 10px;
        }
        &.active &__items {
            display: block;
        }
        &.active &__icon {
            transform: rotate(0);
        }
    }
    &-subcategory {
        @include font(14, 20);
        font-weight: 400;
        color: #fff;
        @include clear-gradient-text(#fff);
        padding: 5px 0;
        display: block;
    }
    &-footer {
        margin-top: 15px;
        width: 100%;
        &__navigation {

        }
        &__phone {
            margin-top: 17.5px;

        }
        &__info {
          @include font(14,20);
          font-weight: 300;
          color: $gray3;
          padding: 2.5px;
        }
        &-nav {
            @include font(14,20);
            font-weight: 300;
            color: $gray3;
            padding: 2.5px 0;
            display: block;
        }
    }
}

#mobileHeader {
    position: sticky;
    top: 0;
    transition: top .5s;
    z-index: 2;
}

.js-header-mobile-active {
    overflow: hidden;
}